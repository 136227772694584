import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';
import { FileUploadService } from 'src/app/services/upload-file.service';
import { UserService } from 'src/app/services/user.service';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent {
  @Output() next = new EventEmitter<any>();
  signinStep=3;
  userForm;
  checkForm;
  loginForm;
  currentUser;
  constructor(
    public formBuilder: FormBuilder,
    public sweetalertsService: SweetalertsService,
    public fileUploadService: FileUploadService,
    public firestoreService: FirestoreService,
    public apiService: ApiService,
    public router: Router,
    public userService: UserService
  ){
    this.userForm = this.formBuilder.group({
      type_doc: ['', Validators.required],
      num_doc: ['', Validators.required],
      name: ['', Validators.required],
      phone: ['', Validators.required],
      contact: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.passwordMatchValidator
    });
    this.checkForm = this.formBuilder.group({
      pin: ['', Validators.required]
    });
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
    })
  }
  signin(){
    this.sweetalertsService.questionAlert("¿Los datos son correctos?","Si","No","Procesando").then((resp)=>{
      if(resp){
        this.apiService.signin(this.userForm.getRawValue()).subscribe((resp)=>{
          this.signinStep=2;
          // this.toggleAccordion(1);
        },(error)=>{
          console.warn("ERRORRRR: ",error);
          if(error['error']){
            this.sweetalertsService.generalWarning(error['message']);
            if(error['statusCode']==406){
              this.router.navigate(['/login']);
              // this.apiService.sendPin({email:this.form.getRawValue().email,tyye:'REGISTER'}).subscribe((resp)=>{})
            }
          }
        })
      }
    })
  }
  login(){
    this.firestoreService.get(`empresas/SUBACOM/users`,this.loginForm.getRawValue().email).subscribe((resp)=>{
      console.info("login: ",resp);
      if(resp){
        if(Utils.comparePasswords(this.loginForm.getRawValue().password,resp['password'])){
          this.userService.setCurrentUser(resp);
          // this.router.navigate(['/main/home']);
          this.sweetalertsService.generalSuccess("Ahora puedes finalizar tu compra");
          //this.userService.setuser()
        }else{
          this.sweetalertsService.generalWarning("Usuario o password inválido");
        }
      }else{
        this.sweetalertsService.generalWarning("Usuario no registrado");
      }
    })
    // this.apiService.loginOnly(this.loginForm.getRawValue()).subscribe((resp)=>{
      
    // },(error)=>{
    //   if(error['error']){
    //     this.sweetalertsService.generalWarning(error['message']);
    //   }
    // })
  }
  sendPin(){
    this.sweetalertsService.questionAlert("¿Reenviar código de verificación?","Si","No","Enviando").then((resp)=>{
      if(resp){
        this.apiService.sendPin({email:this.userForm.getRawValue().email,tyye:'REGISTER'}).subscribe((resp)=>{
    
        })
      }
    })
  }
  checkPin(){
    this.apiService.checkPin({email:this.userForm.getRawValue().email,pin:this.checkForm.getRawValue().pin},true).subscribe((resp)=>{
      this.sweetalertsService.generalSuccess("Verificación completa");
    },(error)=>{
      console.warn("ERRORRRR: ",error);
      if(error['error']){
        this.sweetalertsService.generalWarning(error['message']);
      }
    })
  }
  loginOnly(){
    this.apiService.loginOnly({email:this.userForm.getRawValue().email,tyye:'REGISTER'}).subscribe((resp)=>{
    
    })
  }
  passwordMatchValidator(form: FormGroup) {
    const password = form.get('password');
    const confirmPassword = form.get('confirmPassword');
    if (password.value !== confirmPassword.value) {
      confirmPassword.setErrors({ passwordMismatch: true });
    } else {
      confirmPassword.setErrors(null);
    }
  }
}
