import { Component } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { NavbarService } from 'src/app/services/navbar.service';
import { UserService } from 'src/app/services/user.service';
import { CartComponent } from '../cart/cart.component';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import { SearchFilterMobileComponent } from '../search-filter-mobile/search-filter-mobile.component';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-subheader-sidebar-large',
  templateUrl: './subheader-sidebar-large.component.html',
  styleUrls: ['./subheader-sidebar-large.component.scss']
})
export class SubheaderSidebarLargeComponent {
  currentCart;
  currentUser;
  searchForm;
  constructor(
    public navbarService: NavbarService,
    public userService: UserService,
    public modalController: ModalController,
    public menuController: MenuController,
    public cartService: CartService,
    public formBuilder: FormBuilder,
    public router: Router
  ){
    this.cartService.currentCartSubject$.subscribe((resp)=>{
      this.currentCart=resp;
      // console.warn("currentCart: ",this.currentCart);
    });
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
    })
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required]
    });
  }
  async openModalCart(){
    const modal = await this.modalController.create({
      component: CartComponent,
      cssClass:'cartComponentStyle',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  async openModalSearch(){
    const modal = await this.modalController.create({
      component: SearchFilterMobileComponent,
      // cssClass:'transparentModal',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  reload() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate(['/main/home']));
 }
 search(value){
  switch (value) {
    case "isOffer":
      this.router.navigate(['/main/search'], { queryParams: { sortFilter: "oferta"}});
      break;
  }
 }
}
