import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {

  constructor() { }
  escPress() {
    const escapeEvent = new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
      which: 27,
      keyCode: 27
    });
    document.dispatchEvent(escapeEvent);
  }
}
