<div class="tw-w-full tw-px-10">
    <img class="tw-w-1/4 tw-m-auto tw-mb-2" src="assets/izipay.png" alt="">
    <div id="myPaymentForm" style="display: flex !important;justify-content:center;"></div>
    <!-- <div *ngIf="currentCard" class="tw-text-center" [ngClass]="showPaymentForm?'tw-hidden':'tw-block'">
      <p class="tw-text-sky-600 tw-text-left tw-mb-2 tw-text-xs">Seleccione una tarjeta</p>
      <div class="tw-p-4 tw-grid tw-grid-cols-4 tw-border tw-border-gray-200 tw-bg-white tw-items-center" (click)="openModalSelectCard()">
        <div class="tw-col-span-1 tw-text-center"><i class="fas fa-credit-card tw-text-sky-500 tw-p-2 tw-bg-emerald-100 tw-rounded-full"> </i></div>
        <div class="tw-col-span-2 tw-text-center"><p class="tw-text-gray-500">{{currentCard.cardNumber}}</p></div>
        <div class="tw-col-span-1 tw-text-center"><i class="fas fa-chevron-circle-down tw-text-gray-500"></i></div>
      </div>
    </div> -->
    <div class="tw-text-center" *ngIf="!showPaymentForm"><!-- [ngClass]="showPaymentForm?'tw-hidden':'tw-block'"-->
      <ion-button expand="block" color="transparent" class="tw-rounded-xl tw-my-7 tw-h-12 tw-bg-blue-500 tw-normal-case tw-ion-no-shadow" (click)="confirmar()"><i class="fas fa-check"></i>&nbsp;&nbsp;Confirmar</ion-button>
    </div>
  </div>