import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent implements OnInit {
  @Output() action = new EventEmitter<any>();
  @Input() tableColumns: any[];
  @Input() tableData: any[] = [];
  filteredData: any[] = [];
  searchTerm: string = '';
  pageSize: number = 10;
  pageIndex: number = 0;
  columns=[];
  constructor(
  ) { 
  }

  ngOnInit(): void {
    this.filteredData = this.tableData;
    this.getColumnKeys();
  }

  filterData() {
    this.filteredData = this.tableData.filter(item => {
      return Object.values(item).some(val =>
        val.toString().toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    });
  }
  getColumnKeys() {
    const keys = this.tableColumns.map(column => column.key);
    keys.unshift('acciones');
    return keys;
  }
  clickAction(item){
    this.action.emit(item);
  }
}