import { Input } from '@angular/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Utils } from 'src/app/utils';
import { SweetalertsService } from '../../services/sweetalerts.service';
import { FileUploadService } from 'src/app/services/upload-file.service';

@Component({
  selector: 'app-multifiletableupload',
  templateUrl: './multifiletableupload.component.html',
  styleUrls: ['./multifiletableupload.component.scss']
})
export class MultifiletableuploadComponent implements OnInit {
  files: File[] = [];

  constructor(
    public fileUploadService: FileUploadService,
    public sweetalertsService: SweetalertsService,
  ) { }

  ngOnInit(): void {
  }
  onSelect(event) {
    this.files.push(...event.addedFiles);
    console.log("files: ",this.files);
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
}
