<ion-content>
  <!-- boton responsive cerrar -->
  <div class="tw-block md:tw-hidden">
    <div class="tw-flex tw-justify-end tw-w-full">
      <button class="tw-py-2 tw-px-3" (click)="modalController.dismiss()"><ion-icon class="tw-text-2xl tw-font-bold" slot="icon-only" name="close"></ion-icon></button>
    </div>
  </div>
  <!-- searchBar -->
  <div class="tw-block md:tw-hidden tw-px-3 tw-py-0.5">
    <div *ngTemplateOutlet="searchBar"></div>
  </div>
    <div class="tw-grid tw-grid-cols-12 tw-gap-4 tw-p-2">
        <div [ngClass]="responsiveSearch?'tw-hidden md:tw-block':'tw-block'" class="tw-col-span-12 md:tw-col-span-4 tw-h-[calc(100vh-105px)] md:tw-h-[calc(580px)] tw-overflow-auto">
            <!-- <app-search-filter-v2 (sliderChange)="onPriceChange($event)" (brandsChange)="onBrandsChange($event)"></app-search-filter-v2> -->
            <app-search-filter-v2 id="searchFilterv2search2" #searchFilterv2search2 (sliderChange)="onPriceChange($event)" (brandsChange)="onBrandsChange($event)" (tagChange)="onTagsChange($event)" (search)="onTagsChange($event)"></app-search-filter-v2>
        </div>
        <div [ngClass]="responsiveSearch?'tw-block':'tw-hidden md:tw-block'" class="tw-col-span-12 md:tw-col-span-8">
            <div class="tw-hidden md:tw-block">
              <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
                <span class="tw-font-bold tw-text-lg tw-text-gray-600 tw-pl-4">{{tag}}</span>
                <button class="tw-py-2 tw-px-3" (click)="modalController.dismiss()"><ion-icon class="tw-text-lg tw-font-bold" slot="icon-only" name="close"></ion-icon></button>
              </div>
            </div>
            <div class="tw-px-3">
                <div class="tw-hidden md:tw-block tw-px-3 tw-py-2">
                  <div *ngTemplateOutlet="searchBar"></div>
                </div>
                <div class="tw-h-[calc(100vh-145px)] md:tw-h-[calc(410px)] tw-overflow-auto">
                  <div *ngFor="let item of searchList | customFilter: searchTerm | paginate: { itemsPerPage: limit, currentPage: page, totalItems: totalItems }"  class="tw-grid tw-grid-cols-12 tw-px-2 tw-border-b tw-border-gray-200">
                        <div class="tw-col-span-4 md:tw-col-span-2">
                          <img class="tw-h-30 tw-w-30 md:tw-h-20 md:tw-w-20" [src]="item.urlImagen" alt="" onerror="this.src='assets/notfoundV.png'">
                        </div>
                        <div class="tw-col-span-8 md:tw-col-span-6 tw-px-6 tw-py-2">
                          <p class="tw-my-1">{{item.name}}</p>
                          <p class="tw-font-bold tw-m-0">S/{{item.prices?.discount||''| number:'1.2-2'}}</p>
                        </div>
                        <div class="tw-flex tw-justify-around md:tw-block tw-col-span-12 md:tw-col-span-4">
                            <div class="tw-flex tw-justify-center tw-items-center">
                                <ion-button (click)="qMinus(item)" class="tw-h-8 tw-w-8 tw-normal-case tw-text-stone-900" size="small" fill="outline" color="gray"><i class="fas fa-minus"></i></ion-button>
                                <!-- <ion-button *ngIf="cantidad!=1" (click)="qMinus()" class="tw-h-8 tw-w-8 tw-normal-case tw-text-stone-900" size="small" fill="outline" color="gray"><i class="fas fa-minus"></i></ion-button> -->
                                <div class="tw-border tw-border-gray-300 tw-bg-gray-200 tw-m-1 tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center"><span>{{item.cantidad}}</span></div>
                                <!-- <div class="tw-border tw-border-gray-300 tw-bg-gray-200 tw-m-1 tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center"><span>{{cantidad}}</span></div> -->
                                <ion-button (click)="qPlus(item)" class="tw-h-8 tw-w-8 tw-normal-case tw-text-stone-900" size="small" fill="outline" color="gray"><i class="fas fa-plus"></i></ion-button>
                            </div>
                            <ion-button fill="clear" class="tw-w-48 tw-text-white tw-bg-orange-600 tw-my-2 tw-rounded-md tw-h-10 tw-normal-case" (click)="addProductToBuild(item)"> Seleccionar</ion-button>
                        </div>
                    </div>
                </div>
                <div class="tw-bg-white tw-flex tw-justify-center tw-mt-4">
                    <pagination-controls (pageChange)="search(params,$event);" previousLabel="" nextLabel=""></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</ion-content>

<ng-template #searchBar>
  <div class="tw-px-3 tw-py-0.5 md:tw-py-2">
    <div class="tw-flex tw-items-center">
      <div class="tw-block md:tw-hidden tw-border tw-border-gray-400 tw-rounded-md tw-mr-2">
        <button (click)="responsiveSearch=!responsiveSearch" class="tw-h-10 tw-w-10 tw-my-0 tw-normal-case tw-text-stone-900"><i class="fas fa-filter"></i></button>
      </div>
      <div class="tw-flex tw-items-center tw-border tw-w-full tw-border-gray-400 tw-rounded-md tw-pl-4">
          <input [(ngModel)]="searchTerm" class="tw-rounded-md tw-w-full tw-text-lg tw-h-10 tw-border tw-border-gray-300 tw-mr-1 focus:tw-outline-none tw-border-none" type="text" placeholder="Buscar productos...">
          <div class="tw-bg-orange-600 tw-h-10 tw-w-12 tw-flex tw-items-center tw-justify-center tw-rounded-r-md"><i class="fas fas fa-search tw-font-bold tw-text-base tw-text-white"></i></div>
      </div>
    </div>
  </div>
</ng-template>