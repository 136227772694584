<!-- desktop -->
<div class="tw-hidden md:tw-grid tw-grid-cols-3 tw-w-[calc(50vw)]">
    <div class="tw-overflow-auto tw-h-[calc(70vh)] tw-col-span-1 tw-scrollbar-thin tw-scrollbar-thumb-gray-300 tw-scrollbar-track-gray-100 tw-scrollbar-thumb-rounded">
        <div (mouseenter)="onMouseEnter(item)" *ngFor="let item of categories" class="tw-flex tw-items-center tw-p-2 tw-border tw-border-gray-200">
            <ng-template [ngIf]="item.id != current.id">
                <img class="tw-w-5 tw-h-6" [src]="(item.icon?.file ? 'assets/IMAGENES/IMG NEGRO/' + item.icon.file : 'assets/IMAGENES/IMG NEGRO/procesador.png')" alt="">
            </ng-template>
            <ng-template [ngIf]="item.id == current.id">
                <img class="tw-w-5 tw-h-6" [src]="(item.icon?.file ? 'assets/IMAGENES/IMG NARANJA/' + '_n' + item.icon.file  : 'assets/IMAGENES/IMG NARANJA/_nprocesador.png')" alt="">
            </ng-template>
            <a [routerLink]="['/main/search']" [queryParams]="{ categoryID: item.id , category: item.name }" class="tw-no-underline tw-ml-2" [ngClass]="{'tw-text-orange-600 tw-font-bold': current.id === item.id, 'tw-text-gray-600': current.id !== item.id}" (click)="closeMenuEvent()">{{item.name}}</a>
        </div>
    </div>
    <div class="tw-col-span-2">
        <div *ngIf="current" class="tw-grid tw-grid-cols-2 tw-overflow-auto tw-h-[calc(70vh)] tw-p-4 tw-scrollbar-thin tw-scrollbar-thumb-gray-300 tw-scrollbar-track-gray-100 tw-scrollbar-thumb-rounded">
            <div class="tw-col-span-1 tw-mr-3">
                <div class="tw-py-1.5" [ngClass]="{'tw-hidden':i % 2 === 0}" *ngFor="let item of current.family; let i = index">
                    <div class="tw-grid">
                        <a [routerLink]="['/main/search']" [queryParams]="{ categoryID: current.id , category: current.name , familyID: item.id , family: item.name }" class="tw-font-bold tw-my-2 tw-no-underline tw-text-xs tw-text-gray-600 hover:tw-text-orange-600 tw-uppercase" (click)="closeMenuEvent()">{{item.name}}</a>
                        <a [routerLink]="['/main/search']" [queryParams]="{ categoryID: current.id , category: current.name , familyID: item.id , family: item.name , subfamilyID: sub.id , subfamily: sub.name }" class="tw-no-underline tw-text-xs tw-text-gray-600 hover:tw-text-orange-600 tw-py-1" *ngFor="let sub of item.subFamily" (click)="closeMenuEvent()">{{sub.name}}</a>
                    </div>
                </div>
            </div>
            <div class="tw-col-span-1 tw-ml-3">
                <div class="tw-py-1.5" [ngClass]="{'tw-hidden':i % 2 !== 0}" *ngFor="let item of current.family; let i = index">
                    <div class="tw-grid">
                        <a [routerLink]="['/main/search']" [queryParams]="{ categoryID: current.id , category: current.name , familyID: item.id , family: item.name }" class="tw-font-bold tw-my-2 tw-no-underline tw-text-xs tw-text-gray-600 hover:tw-text-orange-600 tw-uppercase" (click)="closeMenuEvent()">{{item.name}}</a>
                        <a [routerLink]="['/main/search']" [queryParams]="{ categoryID: current.id , category: current.name , familyID: item.id , family: item.name  , subfamilyID: sub.id , subfamily: sub.name }" class="tw-no-underline tw-text-xs tw-text-gray-600 hover:tw-text-orange-600 tw-py-1" *ngFor="let sub of item.subFamily" (click)="closeMenuEvent()">{{sub.name}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- mobile -->
<ion-header class="ion-no-border tw-block md:tw-hidden">
    <ion-toolbar>
      <ion-title class="tw-text-base tw-text-left">Menú de categorias</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="modalController.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="tw-block md:tw-hidden">
    <div class="tw-grid tw-grid-cols-3 tw-w-full">
        <div class="tw-overflow-auto tw-h-[calc(100vh-56px)] tw-col-span-1 tw-scrollbar-thin tw-scrollbar-thumb-gray-300 tw-scrollbar-track-gray-100 tw-scrollbar-thumb-rounded">
            <div (mouseenter)="onMouseEnter(item)" *ngFor="let item of categories" class="tw-p-2 tw-border tw-border-gray-200 tw-text-center">
                <img class="tw-w-5 tw-h-6 tw-m-auto" [src]="(item.icon?.file ? 'assets/IMAGENES/IMG NEGRO/' + item.icon.file : 'assets/IMAGENES/IMG NEGRO/procesador.png')" alt="">
                <a class="tw-text-xs tw-no-underline tw-ml-2" [ngClass]="{'tw-text-orange-600 tw-font-bold': current.id === item.id, 'tw-text-gray-600': current.id !== item.id}">{{ item.name }}</a>
            </div>
        </div>
        <div class="tw-col-span-2">
            <div *ngIf="current" class="tw-overflow-auto tw-h-[calc(100vh-56px)] tw-px-4 tw-scrollbar-thin tw-scrollbar-thumb-gray-300 tw-scrollbar-track-gray-100 tw-scrollbar-thumb-rounded">
                <div *ngFor="let item of current.family; let i = index">
                    <div class="tw-grid">
                        <a [routerLink]="['/main/search']" [queryParams]="{ categoryID: current.id , category: current.name , familyID: item.id , family: item.name }" class="tw-font-bold tw-my-2 tw-no-underline tw-text-xs tw-text-gray-600 hover:tw-text-orange-600 tw-uppercase" (click)="modalController.dismiss()">{{item.name}}</a>
                        <a [routerLink]="['/main/search']" [queryParams]="{ categoryID: current.id , category: current.name , familyID: item.id , family: item.name , subfamilyID: sub.id , subfamily: sub.name }" class="tw-no-underline tw-text-xs tw-text-gray-600 hover:tw-text-orange-600" *ngFor="let sub of item.subFamily" (click)="modalController.dismiss()">{{sub.name}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>