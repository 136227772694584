import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  navbarOpenSubject$;
  searchSubject$;
  searchBarSubject$;
  // opacitySubject$;
  constructor() { 
    // this.navbarOpenSubject$ = new BehaviorSubject(!(window.innerWidth > 768));//true show navbar,false mobile not show sidebar
    this.navbarOpenSubject$ = new BehaviorSubject(false);//true show navbar,false mobile not show sidebar
    this.searchSubject$ = new Subject();
    this.searchBarSubject$ = new Subject();
    // this.opacitySubject$ = new Subject();
  }
}
