import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ModalDetalleProductComponent } from 'src/app/pages/main/modal-detalle-product/modal-detalle-product.component';
import { ModalAddProductComponent } from 'src/app/pages/modal-add-product/modal-add-product.component';
import { ModalFichaComponent } from 'src/app/pages/modal-ficha/modal-ficha.component';
import { CartService } from 'src/app/services/cart.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-card-product-v2',
  templateUrl: './card-product-v2.component.html',
  styleUrls: ['./card-product-v2.component.scss']
})
export class CardProductV2Component {
  @Input() item;
  currentUser;
  constructor(
    public userService: UserService,
    public modalController: ModalController,
    public cartService: CartService,
    public firestoreService: FirestoreService,
    public sweetalertsService: SweetalertsService,
    public router: Router
  ){
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
    })
  }
  async openModalAddProduct(selectedProduct){
    const modal = await this.modalController.create({
      component: ModalAddProductComponent,
      cssClass: 'modalDetalleProducto',
      componentProps: {'selectedProduct': selectedProduct}
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  async openModalVerFicha(item) {
    const modal = await this.modalController.create({
      component: ModalFichaComponent,
      componentProps: {
        item:item
      },
      cssClass: 'modalVerFicha',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  async openModalDetalleProduct(item) {
    const modal = await this.modalController.create({
      component: ModalDetalleProductComponent,
      componentProps: {
        item:item
      },
      cssClass: 'modalVerFicha',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  public qMinus(item){
    if(+item.cantidad>1){
      item.cantidad = +item.cantidad-1;
    }
  }
  public qPlus(item){
    if(+item.cantidad<100){
      item.cantidad = +item.cantidad+1;
    }
  }
  showLikeEye(){
    
  }
  saveFavorite(item){
    this.sweetalertsService.questionAlert("¿Agregar a favoritos?","Si","No","Procesando").then((resp)=>{
      if(resp){
        let tempId=new Date().getTime();
        this.firestoreService.set(`empresas/SUBACOM/users/${this.currentUser.email}/favoritos/`,this.sanitizeProduct(Object.assign({},item,{active:true})),tempId).subscribe((resp)=>{
          this.sweetalertsService.generalSuccess("Favorito agregado");
        },(error)=>{
          this.sweetalertsService.generalError("No se pudo agregar a favoritos");
        });
      }
    });
  }
  sanitizeProduct(item){
    let tempProd=JSON.parse(JSON.stringify(item));
    delete tempProd.descripcionLargaHtml;
    delete tempProd.fichaTecnicaHtml;
    return tempProd;
  }
  addProductToCart(item){
    this.sweetalertsService.questionAlert("¿Añadir al carrito?","Si","No","Procesando").then((resp)=>{
      if(resp){
        this.cartService.addProductToCart(item,1);
      }
    })
  }
  addProductToCartDirect(item){
    this.cartService.addProductToCart(item,1);
    this.router.navigate(['/main/checkout']);
  }

}
