import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';

@Component({
  selector: 'app-search-filter-content',
  templateUrl: './search-filter-content.component.html',
  styleUrls: ['./search-filter-content.component.scss']
})
export class SearchFilterContentComponent {
  @ViewChild('ionSearchbar') ionSearchbar!: IonSearchbar;
  @Output() searchEvent = new EventEmitter<any>();
  @Input() title;
  @Input() content;
  searchValue;
  
  constructor(){

  }
  ngOnInit(){

  }
  search(){
    this.searchEvent.emit({});
  }
  clear(){
    this.content.forEach((item)=>{item.selected=false;})
  }
}
