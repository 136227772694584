<div class="tw-hidden md:tw-block tw-bg-white tw-px-20 tw-z-30 tw-w-full tw-mt-24">
    <div *ngTemplateOutlet="menu"></div>
</div>
<div class="tw-block md:tw-hidden tw-bg-white tw-z-30 tw-top-20 md:tw-top-24 tw-w-full">
    <div class="tw-flex tw-justify-around tw-items-center tw-h-16">
        <button>
            <div class="tw-m-auto tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-bg-orange-600 tw-rounded-full">
                <img class="tw-w-5 tw-h-5" src="assets/IMAGENES/IMG BLANCO/Group 130-1.png" alt="">
            </div>
            <span class="tw-text-stone-900 tw-font-bold">Tiendas</span>
        </button>
        <button routerLink="/main/arma-tu-pc">
            <div class="tw-m-auto tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-bg-orange-600 tw-rounded-full">
                <img class="tw-w-5 tw-h-5" src="assets/IMAGENES/IMG BLANCO/Group 128-1.png" alt="">
            </div>
            <span class="tw-text-stone-900 tw-font-bold">Arma tu PC</span>
        </button>
        <button>
            <div class="tw-m-auto tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-bg-orange-600 tw-rounded-full">
                <img class="tw-w-5 tw-h-5" src="assets/IMAGENES/IMG BLANCO/Group 161.png" alt="">
            </div>
            <span class="tw-text-stone-900 tw-font-bold">Otros</span>
        </button>
        <div uk-drop="mode: click;; pos: bottom-center; offset: 5">
            <div class="tw-flex tw-justify-center">
                <div class="tw-bg-white tw-w-3/4 tw-shadow-md tw-border tw-border-gray-300">
                    <div *ngTemplateOutlet="menu"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #menu>
    <div class="tw-block md:tw-flex tw-justify-around tw-items-center tw-h-full md:tw-h-20">
        <div class="tw-flex tw-justify-center tw-items-center">
            <button class="tw-w-full tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-bg-orange-600 tw-px-3 tw-py-2 tw-rounded-sm" (click)="search('isOffer')">
                <img class="tw-w-7 tw-h-7" src="assets/IMAGENES/IMG BLANCO/Group 168.png" alt="">
                <span class="tw-text-white tw-font-bold tw-ml-3">Ofertas SUBACOM</span>
            </button>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center">
            <button class="tw-w-full tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-px-3 tw-py-2" (click)="search('minprice')">
                <img class="tw-w-7 tw-h-7" src="assets/IMAGENES/IMG NEGRO 2/Group 166.png" alt="">
                <span class="tw-text-stone-900 tw-font-bold tw-ml-3">Remates</span>
            </button>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center">
            <button class="tw-w-full tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-px-3 tw-py-2">
                <img class="tw-w-7 tw-h-7" src="assets/IMAGENES/IMG NEGRO 2/Group 131.png" alt="">
                <span class="tw-text-stone-900 tw-font-bold tw-ml-3">Nuestros locales</span>
            </button>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center">
            <button class="tw-w-full tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-px-3 tw-py-2">
                <img class="tw-w-7 tw-h-7" src="assets/IMAGENES/IMG NEGRO 2/Group 167.png" alt="">
                <span class="tw-text-stone-900 tw-font-bold tw-ml-3">Modalidades de pago</span>
            </button>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center">
            <button class="tw-w-full tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-px-3 tw-py-2">
                <img class="tw-w-7 tw-h-7" src="assets/IMAGENES/IMG NEGRO 2/Group 168.png" alt="">
                <span class="tw-text-stone-900 tw-font-bold tw-ml-3">Envios a Arequipa</span>
            </button>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center">
            <button class="tw-w-full tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-px-3 tw-py-2">
                <img class="tw-w-7 tw-h-7" src="assets/IMAGENES/IMG NEGRO 2/Group 168.png" alt="">
                <span class="tw-text-stone-900 tw-font-bold tw-ml-3">Envios a provincia</span>
            </button>
        </div>
        <!-- se pasaron lineas desde app-user-address -->
        <div class="tw-flex tw-justify-center tw-items-center">
            <button class="tw-w-52 tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-px-3 tw-py-2 tw-bg-gray-200 tw-rounded-md">
                <i class="fas fa-map-marker-alt tw-text-xl tw-text-gray-400"></i>
                <span class="tw-text-stone-900 tw-font-bold tw-ml-3 tw-line-clamp-2">Calle Octavio Muñoz Najar N°223, Centro Comercial CompuArequipa</span>
            </button>
        </div>
        <div class="tw-flex tw-justify-center tw-items-center">
            <button routerLink="/main/locales" class="tw-w-52 tw-flex tw-justify-start md:tw-justify-between tw-items-center tw-bg-orange-600 tw-px-3 tw-py-1 tw-rounded-md">
                <i class="fas fa-map-marker-alt tw-text-xl tw-text-white"></i>
                <div>
                    <p class="tw-text-white tw-font-bold tw-ml-3 tw-mb-0 tw-text-xs">Sede Arequipa</p>
                    <p class="tw-text-white tw-font-bold tw-ml-3 tw-mb-0 tw-text-sm">CompuArequipa</p>
                </div>
                <i class="fas fas fa-chevron-circle-right tw-text-xl tw-text-white"></i>
            </button>
        </div>
    </div>
</ng-template>