import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform(array: any[], sortOrder): any[] {
    if (!array || array.length <= 1) {
      return array;
    }

    if (sortOrder === 'asc') {
      console.log('Sorting in ascending order by prices.discount');
      const sortedArray = array.slice().sort((a, b) => (+a.prices.discount > +b.prices.discount) ? 1 : -1);
      console.log('Sorted Array (Asc) by prices.discount: ', sortedArray);
      return sortedArray;
    } else if (sortOrder === 'desc') {
      console.log('Sorting in descending order by prices.discount');
      const sortedArray = array.slice().sort((a, b) => (+a.prices.discount < +b.prices.discount) ? 1 : -1);
      console.log('Sorted Array (Desc) by prices.discount: ', sortedArray);
      return sortedArray;
    } else if (sortOrder === 'name') {
      console.log('Sorting in ascending order by name');
      const sortedArray = array.slice().sort((a, b) => (a.name > b.name) ? 1 : -1);
      console.log('Sorted Array (Asc) by name: ', sortedArray);
      return sortedArray;
    } else if (sortOrder === 'esDestacado') {
      console.log('Sorting in esDestacado by tag');
      const sortedArray = array.slice().sort((a, b) => {
        if (a.esDestacado === 'Y' && b.esDestacado === 'N') {
          return -1;
        } else if (a.esDestacado === 'N' && b.esDestacado === 'Y') {
          return 1;
        } else {
          return 0;
        }
      });
      console.log('Sorted Array (esDestacado) by tag: ', sortedArray);
      return sortedArray;
    } else if (sortOrder === 'esOferta') {
      console.log('Sorting in esOferta by tag');
      const sortedArray = array.slice().sort((a, b) => {
        if (a.esOferta === 'Y' && b.esOferta === 'N') {
          return -1;
        } else if (a.esOferta === 'N' && b.esOferta === 'Y') {
          return 1;
        } else {
          return 0;
        }
      });
      console.log('Sorted Array (esOferta) by tag: ', sortedArray);
      return sortedArray;
    } else if (sortOrder === 'esNovedad') {
      console.log('Sorting in esNovedad by tag');
      const sortedArray = array.slice().sort((a, b) => {
        if (a.esNovedad === 'Y' && b.esNovedad === 'N') {
          return -1;
        } else if (a.esNovedad === 'N' && b.esNovedad === 'Y') {
          return 1;
        } else {
          return 0;
        }
      });
      console.log('Sorted Array (esNovedad) by tag: ', sortedArray);
      return sortedArray;
    } else if (sortOrder === 'ranking') {
      console.log('Sorting in ranking order by ranking');
      const sortedArray = array.slice().sort((a, b) => (+a.ranking < +b.ranking) ? 1 : -1);
      console.log('Sorted Array (ranking) by ranking: ', sortedArray);
      return sortedArray;
    } else {
      return array;
    }
  }
}