import { Component, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SearchFilterV2Component } from 'src/app/components/search-filter-v2/search-filter-v2.component';

@Component({
  selector: 'app-modal-filter',
  templateUrl: './modal-filter.component.html',
})
export class ModalFilterComponent {
  @ViewChild('searchFilterv2') searchFilterv2!: SearchFilterV2Component;
  constructor(
    public modalController: ModalController
  ){}
  filter(){
    this.modalController.dismiss({
      filtersList:this.searchFilterv2.currentFilters
    });
  }
}
