import { NgModule } from '@angular/core';
import { CustomFilterPipe } from '../pages/modal-search-add-product/custom-filter.pipe/custom-filter.pipe';
import { SafePipe } from '../safe.pipe';
import { SortPipe } from '../pages/modal-search-add-product/sort.pipe/sort.pipe';

const pipes=[
  CustomFilterPipe,
  SafePipe,
  SortPipe
]
@NgModule({
  declarations: [
    pipes
  ],
  imports: [
    
  ],
  exports:[
    pipes
  ]
})
export class PipesModule { }
