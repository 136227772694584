import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { HeaderSidebarLargeComponent } from './header-sidebar-large/header-sidebar-large.component';
import { StepperComponent } from './stepper/stepper.component';
import { CameraPreviewComponent } from './camera-preview/camera-preview.component';
import { IonicModule } from '@ionic/angular';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { SearchFilterContentComponent } from './search-filter-content/search-filter-content.component';
import { NgPipesModule } from 'ngx-pipes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartComponent } from './cart/cart.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { SearchFilterMobileComponent } from './search-filter-mobile/search-filter-mobile.component';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MultifiletableuploadComponent } from './multifiletableupload/multifiletableupload.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { IzipayComponent } from './izipay/izipay.component';
import { CardProductComponent } from './card-product/card-product.component';
import { SubheaderSidebarLargeComponent } from './subheader-sidebar-large/subheader-sidebar-large.component';
import { CategoryMenuComponent } from './category-menu/category-menu.component';
import { FooterComponent } from './footer/footer.component';
import { CardProductV2Component } from './card-product-v2/card-product-v2.component';
import { SearchFilterV2Component } from './search-filter-v2/search-filter-v2.component';
import { MatSliderModule } from '@angular/material/slider';
import { UserAddressBarComponent } from './user-address-bar/user-address-bar.component';
import { DetailAddProductComponent } from './detail-add-product/detail-add-product.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { DireccionesComponentComponent } from '../pages/main/profile/direcciones/direcciones-component/direcciones-component.component';

@NgModule({
  declarations: [
    NavbarComponent,
    HeaderSidebarLargeComponent,
    StepperComponent,
    CameraPreviewComponent,
    SearchFilterComponent,
    SearchFilterContentComponent,
    CartComponent,
    SearchFilterMobileComponent,
    LoginRegisterComponent,
    MultifiletableuploadComponent,
    DynamicTableComponent,
    IzipayComponent,
    CardProductComponent,
    CardProductV2Component,
    SubheaderSidebarLargeComponent,
    CategoryMenuComponent,
    FooterComponent,
    SearchFilterV2Component,
    UserAddressBarComponent,
    DetailAddProductComponent,
    DireccionesComponentComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NgPipesModule,  
    RouterModule,
    NgxDropzoneModule,
    NgxGalleryModule,

    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSliderModule
  ],
  exports:[
    NavbarComponent,
    HeaderSidebarLargeComponent,
    StepperComponent,
    CameraPreviewComponent,
    SearchFilterComponent,
    SearchFilterContentComponent,
    CartComponent,
    SearchFilterMobileComponent,
    LoginRegisterComponent,
    MultifiletableuploadComponent,
    DynamicTableComponent,
    IzipayComponent,
    CardProductComponent,
    CardProductV2Component,
    SubheaderSidebarLargeComponent,
    CategoryMenuComponent,
    FooterComponent,
    SearchFilterV2Component,
    UserAddressBarComponent,
    DetailAddProductComponent,
    DireccionesComponentComponent
  ]
})
export class ComponentsModule { }
