<ion-content>
    <div class="tw-flex tw-w-full tw-justify-end">
        <button class="tw-p-2" (click)="modalController.dismiss()"><ion-icon class="tw-text-2xl" slot="icon-only" name="close"></ion-icon></button>
    </div>
    <app-search-filter-v2 #searchFilterv2 id="searchFilterv2"></app-search-filter-v2>
</ion-content>
<ion-footer class="tw-bg-white">
    <div class="tw-flex tw-px-6">
        <button class="tw-w-full tw-text-white tw-bg-orange-600 tw-my-3 tw-py-2 tw-rounded-lg tw-h-10" (click)="filter()">Aplicar</button>
    </div>
</ion-footer>