import { Component, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal-ficha',
  templateUrl: './modal-ficha.component.html',
  styleUrls: ['./modal-ficha.component.scss']
})
export class ModalFichaComponent {
  item;
  constructor(
    private navParams: NavParams,
    public modalController: ModalController,
  ) {
  }
  ngOnInit(){
    this.item = this.navParams.get('item');
  }

}
