import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalDetalleProductComponent } from 'src/app/pages/main/modal-detalle-product/modal-detalle-product.component';
import { ModalAddProductComponent } from 'src/app/pages/modal-add-product/modal-add-product.component';
import { ModalFichaComponent } from 'src/app/pages/modal-ficha/modal-ficha.component';
import { CartService } from 'src/app/services/cart.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';

@Component({
  selector: 'app-card-product',
  templateUrl: './card-product.component.html',
  styleUrls: ['./card-product.component.scss']
})
export class CardProductComponent {
  @Input() item;
  constructor(
    public modalController: ModalController,
    public firestoreService: FirestoreService,
    public cartService: CartService,
    public sweetalertsService: SweetalertsService
  ){
    
  }
  async openModalAddProduct(selectedProduct){
    const modal = await this.modalController.create({
      component: ModalAddProductComponent,
      cssClass: 'modalVerFicha',
      componentProps: {'selectedProduct': selectedProduct}
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  async openModalVerFicha(item) {
    const modal = await this.modalController.create({
      component: ModalFichaComponent,
      componentProps: {
        item:item
      },
      cssClass: 'modalVerFicha',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  async openModalDetalleProduct(item) {
    const modal = await this.modalController.create({
      component: ModalDetalleProductComponent,
      componentProps: {
        item:item
      },
      cssClass: 'modalVerFicha',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  public qMinus(item){
    if(+item.cantidad>1){
      item.cantidad = +item.cantidad-1;
    }
  }
  public qPlus(item){
    if(+item.cantidad<100){
      item.cantidad = +item.cantidad+1;
    }
  }
  showLikeEye(){
    
  }
  addProductToCart(){
    this.sweetalertsService.questionAlert("¿Añadir al carrito?","Si","No","Procesando").then((resp)=>{
      if(resp){
        this.cartService.addProductToCart(this.item,1);
      }
    })
  }
}
