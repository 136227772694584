<div class="tw-grid tw-grid-flow-col tw-bg-blue-950">
    <div class="tw-col-span-1 tw-flex tw-items-center tw-p-1 md:tw-p-4 tw-justify-around" (click)="emitStep.emit(i)" *ngFor="let item of content; let i=index">
        <div class="tw-flex tw-items-center">
            <div [ngClass]="step==i?'tw-bg-amber-400':'tw-bg-blue-900'" class="tw-rounded-full tw-py-1 tw-px-3">
                <span class="tw-text-white">{{i+1}}</span>
            </div>
            <span class="tw-text-white tw-font-bold tw-text-center tw-hidden md:tw-block tw-ml-2">{{item}}</span>
        </div>
        <!-- <span class="tw-text-white tw-font-bold tw-text-center tw-hidden md:tw-block'">{{item}}</span> -->
        <i *ngIf="i !== content.length - 1" class="fas fa-arrow-right tw-text-white tw-text-2xl tw-font-bold"></i>
    </div>
</div>
<div class="tw-grid tw-grid-flow-col tw-bg-blue-950">
    <p class="tw-text-white tw-font-bold tw-text-center tw-block md:tw-hidden tw-mb-0">{{content[step]}}</p>
</div>