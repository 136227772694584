  <div>
    <!-- <div class="tw-flex tw-w-full tw-justify-end">
      <button class="tw-p-2" (click)="modalController.dismiss()"><ion-icon clas="tw-text-lg" slot="icon-only" name="close"></ion-icon></button>
    </div> -->
    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
      <div class="tw-col-span-1 tw-px-3 tw-h-64 md:tw-h-full">
        <ngx-gallery #ngxGallery id="ngxGallery" [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
      </div>
      <div class="tw-col-span-1 tw-px-2">
        <p class="tw-text-sm md:tw-text-lg tw-font-bold tw-px-3 tw-text-left tw-pt-2">{{selectedProduct.name}}</p>
        <div class="tw-flex tw-p-4">
          <div class="tw-w-full">
            <div>
              <p class="tw-text-sm md:tw-text-base"><strong>Sku: &nbsp;</strong>{{selectedProduct.sku}}</p>
              <div class="tw-flex tw-items-center tw-justify-between">
                <p class="tw-text-sm md:tw-text-base tw-m-0"><strong>Stock: &nbsp;</strong><span class="tw-text-green-600 ">
                  {{selectedProduct.stock?.qty <= 10? ' Solo ' + (selectedProduct.stock?.qty || 0) : '+' + 10 }} en Stock
                </span></p>
                <div class="tw-flex tw-items-center">
                  <ng-container *ngFor="let i of [].constructor(5); let index = index">
                    <div class="tw-px-0.5">
                      <i [ngClass]="index < selectedProduct.ranking ? 'fas fa-star tw-text-amber-500 tw-text-xs md:tw-text-lg' : 'far fa-star tw-text-amber-500 tw-text-xs md:tw-text-lg'"></i>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="tw-hidden md:tw-block tw-w-full tw-border-t tw-border-gray-300 tw-py-4">
              <div class="tw-flex">
                <div class="tw-w-1/2"><span class="tw-font-bold tw-text-lg">Pagando con Efectivo</span></div>
                <div class="tw-w-1/2"><span class="tw-font-bold tw-text-lg">Pagando con Tarjeta (+5%)</span></div>
              </div>
              <div class="tw-flex tw-py-3">
                <div class="tw-w-1/2"><span class="tw-font-bold tw-text-orange-600 tw-text-lg">S/{{selectedProduct.prices?.discount||''| number:'1.2-2'}}</span></div>
                <div class="tw-w-1/2"><span class="tw-font-bold tw-text-orange-600 tw-text-lg">S/{{selectedProduct.prices?.discount*1.05||''| number:'1.2-2'}}</span></div>
              </div>
            </div>
            <div class="tw-block md:tw-hidden tw-w-full tw-border-t tw-border-gray-300 tw-py-1 md:tw-py-4">
              <div>
                <div class="tw-w-full"><span class="tw-font-bold tw-text-lg">Pagando con Efectivo</span></div>
                <div class="tw-w-full"><span class="tw-font-bold tw-text-orange-600 tw-text-lg">S/{{selectedProduct.prices?.discount||''| number:'1.2-2'}}</span></div>
              </div>
              <div class="tw-py-3">
                <div class="tw-w-full"><span class="tw-font-bold tw-text-lg">Pagando con Tarjeta (+5%)</span></div>
                <div class="tw-w-full"><span class="tw-font-bold tw-text-orange-600 tw-text-lg">S/{{selectedProduct.prices?.discount*1.05||''| number:'1.2-2'}}</span></div>
              </div>
            </div>
            <div>
              <div class="tw-hidden md:tw-block tw-w-full"><span class="tw-font-bold tw-text-lg">Detalle de producto</span></div>
              <!-- <div *ngIf="selectedProduct.descriptionFull" class="tw-m-auto tw-py-6" [innerHTML]="getSafeHtml(selectedProduct.descriptionFull)"></div> -->
            </div>
            <div class="tw-flex">
              <ion-button *ngIf="cantidad==1" (click)="modalController.dismiss()" class="tw-h-8 tw-w-8 tw-normal-case tw-text-stone-900" size="small" fill="outline" color="gray"><i class="far fa-trash-alt"></i></ion-button>
              <ion-button *ngIf="cantidad!=1" (click)="qMinus()" class="tw-h-8 tw-w-8 tw-normal-case tw-text-stone-900" size="small" fill="outline" color="gray"><i class="fas fa-minus"></i></ion-button>
              <div class="tw-border tw-border-gray-300 tw-bg-gray-200 tw-m-1 tw-h-8 tw-w-8 tw-flex tw-justify-center tw-items-center"><span>{{cantidad}}</span></div>
              <ion-button (click)="qPlus()" class="tw-h-8 tw-w-8 tw-normal-case tw-text-stone-900" size="small" fill="outline" color="gray"><i class="fas fa-plus"></i></ion-button>
              <ion-button (click)="addProductToCart()" class="tw-h-8 tw-normal-case tw-w-full md:tw-w-auto" size="small" fill="outline" color="orange"><i class="fas fa-shopping-cart"></i>&nbsp;&nbsp;Añadir al carrito</ion-button>
              <ion-button (click)="addProductToCartDirect()" routerLink="/main/checkout" class="tw-hidden md:tw-block tw-h-8 tw-w-40 tw-normal-case" size="small" color="orange">Comprar</ion-button>
            </div>
            <div>
              <ion-button (click)="addProductToCartDirect()" routerLink="/main/checkout" class="tw-block md:tw-hidden tw-h-8 tw-normal-case" size="small" color="orange">Comprar</ion-button>
              <ion-button [href]="'https://api.whatsapp.com/send?text='+firestoreService.empresaData?.messageStock+' '+selectedProduct.name+' '+'&phone=' + firestoreService.empresaData?.telefono" target="_blank" class="tw-h-8 tw-w-full md:tw-w-40 tw-normal-case" size="small" color="success"><i class="fab fa-whatsapp"></i>&nbsp;&nbsp;Asesor online</ion-button>
              <ion-button *ngIf="currentUser" (click)="saveFavorite(selectedProduct)" class="tw-h-8 tw-normal-case tw-w-full md:tw-w-auto" size="small" fill="outline" color="orange"><i class="far fa-heart"></i>&nbsp;&nbsp;Añadir a favoritos</ion-button>
            </div>
            <div>
              <div class="tw-w-full"><span class="tw-font-bold tw-text-lg">Paga con recargo de 5% adicional con tarjetas de crédito/débito: </span></div>
              <div class="tw-flex tw-py-3">
                <img class="tw-mx-2" src="assets/IMAGENES/LOGOS 1/Group 93-1.png" alt="">
                <img class="tw-mx-2" src="assets/IMAGENES/LOGOS 1/Group 94-1.png" alt="">
              </div>
            </div>
            <div>
              <div class="tw-w-full"><span class="tw-font-bold tw-text-lg">Paga sin recargo por deposito o transferencia bancaria con: </span></div>
              <div class="tw-grid tw-grid-cols-3 tw-gap-2 md:tw-flex tw-py-3">
                <img class="tw-mx-2" src="assets/IMAGENES/LOGOS 1/Group 97.png" alt="">
                <img class="tw-mx-2" src="assets/IMAGENES/LOGOS 1/Group 96.png" alt="">
                <img class="tw-mx-2" src="assets/IMAGENES/LOGOS 1/Group 95.png" alt="">
                <img class="tw-mx-2" src="assets/IMAGENES/LOGOS 1/Group 99.png" alt="">
                <img class="tw-mx-2" src="assets/IMAGENES/LOGOS 1/Group 98.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>