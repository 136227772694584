import { Component, ViewChild } from '@angular/core';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-search-filter-mobile',
  templateUrl: './search-filter-mobile.component.html',
  styleUrls: ['./search-filter-mobile.component.scss']
})
export class SearchFilterMobileComponent {
  @ViewChild('ionSearchbar') ionSearchbar!: IonSearchbar;
  @ViewChild('brandIonSearchbar') brandIonSearchbar!: IonSearchbar;
  _listMarcas;
  searchValue;
  constructor(
    public modalController: ModalController,
    public apiService: ApiService,
    public navbarService: NavbarService
  ){

  }
  ngOnInit(){
    this.listMarcas();
  }
  listMarcas(){
    this.apiService.listMarcas().subscribe((resp)=>{
      this._listMarcas=resp;
    })
  }
  search() {
    let selectedBrands = this._listMarcas.filter(brand => brand.selected).map(brand => brand.id).join(',');
    if (selectedBrands.endsWith(',')) {
      selectedBrands = selectedBrands.slice(0, -1);
    }
    this.navbarService.searchSubject$.next({
      search: this.ionSearchbar.value,
      // tags: selectedTags,
      brands: selectedBrands,
    });
    this.modalController.dismiss();
  }
  searchTableros() {
    this.navbarService.searchSubject$.next({
      isBom: true,
    });
    this.modalController.dismiss();
  }
}
