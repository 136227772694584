import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CartService } from 'src/app/services/cart.service';
import { KeyboardService } from 'src/app/services/keyboard.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {
  currentOrderAmounts;
  currentCart;
  constructor(
    public modalController: ModalController,
    public cartService: CartService,
    public sweetalertsService: SweetalertsService,
    public keyboardService: KeyboardService
  ){
    this.cartService.currentCartSubject$.subscribe((resp)=>{
      this.currentCart=resp;
      // console.warn("currentCart: ",this.currentCart);
    });
    this.cartService.currentOrderAmountsSubject$.subscribe((resp)=>{
      this.currentOrderAmounts=resp;
      console.info("currentOrderAmounts: ",this.currentOrderAmounts);
    })
  }
  public removeItem(i){
    this.sweetalertsService.questionAlert("¿Desea eliminar el producto?","Si","No","Producto eliminado").then((resp)=>{
      if(resp){
        this.cartService.removeProductFromCart(i);
        this.cartService.updateCart(this.currentCart);
      }
    })
  }
  public qMinus(item){
    if(+item.cantidad>1){
      item.cantidad = +item.cantidad-1;
      this.cartService.updateCart(this.currentCart);
    }
  }
  public qPlus(item){
    if(+item.cantidad<item.stock.qty){
      item.cantidad = +item.cantidad+1;
      this.cartService.updateCart(this.currentCart);
    }
  }
}
