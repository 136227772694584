import {HttpRequest,HttpHandler,HttpEvent,HttpInterceptor,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import { defer, Observable, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { environment } from 'src/environments/environment';
  
  @Injectable()
  export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(
        ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = "my-token-string-from-server";
    return defer(() => {
        const key = request.urlWithParams;
        let peticion;
        let respuesta;
        let tiempo;
        var begin=Date.now();
        return next.handle(request).pipe(
            finalize(()=>{
                var end= Date.now();
                tiempo=(end-begin)/1000+"secs";
                let body;
                if(respuesta){
                    if(respuesta['status']==200){
                        body={
                            path:respuesta['url'],
                            body:this.convertToJson(peticion['body']),
                            bodyRaw:JSON.stringify(peticion['body']),
                            status:respuesta['status'],
                            response:JSON.stringify(respuesta['body']),
                            delay:tiempo,
                            fechaHora:moment().utcOffset('-05:00').format("HH:MM DD-MM-YYYY")
                        }
                    }else{
                        body={
                            path:respuesta['url'],
                            body:this.convertToJson(peticion['body']),
                            bodyRaw:JSON.stringify(peticion['body']),
                            status:respuesta['status'],
                            response:JSON.stringify(respuesta['body']),
                            delay:tiempo,
                            fechaHora:moment().utcOffset('-05:00').format("HH:MM DD-MM-YYYY")
                        }
                    }
                }
                if(environment.production){
                    console.warn("BODY: ", body);
                }else{
                    if(body){
                        let tempBody=body;
                        tempBody.body=JSON.parse(tempBody.body);
                        tempBody.response=JSON.parse(tempBody.response);
                        console.warn("BODY: ", tempBody);
                    }
                }
            }),
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    peticion=request;
                    respuesta=event;
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let body = {
                    path: error.url,
                    body: request['body'] ? request['body'] : null,
                    bodyRaw: request['body'] ? JSON.stringify(request['body']) : null,
                    status: error.status,
                    response: JSON.stringify(error.error),
                    delay: tiempo,
                    fechaHora: moment().utcOffset('-05:00').format("HH:MM DD-MM-YYYY")
                  };
                  console.error("BODY: ", body);
                  console.error(error);
                  let errorMsg = '';
                  if (error.error instanceof ErrorEvent) {
                    console.log('This is client side error');
                    errorMsg = `Error: ${error.error.message}`;
                  } else {
                    console.log('This is server side error');
                    errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                  }
                  console.log(errorMsg);
                  return throwError(error.error);
            }));
        });
    }
  
    convertToJson(formData){
        var object = {};
        if(this.isIterable(formData)){
            formData.forEach((value, key) => object[key] = value);            
            return JSON.stringify(object);
        }else{
            return JSON.stringify(formData);
        }
    }
    isIterable(obj) {
        if (obj == null) {
            return false;
        }
        return typeof obj[Symbol.iterator] === 'function';
    }
  
  }