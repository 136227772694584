import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html'
})
export class StepperComponent {
  @Input() step;
  @Input() content;
  @Output() emitStep = new EventEmitter<any>();

}
