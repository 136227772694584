import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { environment } from 'src/environments/environment.prod';
import KRGlue from "@lyracom/embedded-form-glue";

@Component({
  selector: 'app-izipay',
  templateUrl: './izipay.component.html',
  styleUrls: ['./izipay.component.scss']
})
export class IzipayComponent {
  @Output() paymentEvent = new EventEmitter<any>();
  @ViewChild("checkout") checkoutModal: ElementRef;
  @Input() amount;
  showPaymentForm : boolean = false;
  currentUser;
  status_pay : string = '';
  extraParams;

  constructor(
    public userService: UserService,
    public sweetalertsService: SweetalertsService,
    public apiService: ApiService,
    private elementRef: ElementRef<HTMLElement>,
    public utilsService: UtilsService
  ){
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
    })
  }
  initPayment(){
    console.info("initPayment");
    this.showPaymentForm=true;
    this.sweetalertsService.loading("Espere por favor");
    let body={
      "amount": this.amount * 100,
      "currency": "PEN",
      "formAction": "ASK_REGISTER_PAY",
      "transactionOptions":{
        "cardOptions":{
          "installmentNumber":0,
        },
      },
      "orderId":  Date.now(),//"myOrderId-999999",
      "customer": {
        "email":this.currentUser.email,
      }
    }
    console.info("initPaymentbody: ",body);
    // console.log(body)
    this.apiService.getFormToken(body).subscribe((resp:any)=>{
      setTimeout(()=>{this.sweetalertsService.dissmissSwal();},3000)
      const endpoint = "https://static.micuentaweb.pe";
      const publicKey = environment.iziPayPK;
      const formToken = resp['data'];
      KRGlue.loadLibrary(endpoint, publicKey)
        .then(({ KR }) => KR.setFormConfig({
            formToken: formToken,
            "kr-language": "es-ES",
            "kr-post-url-success":"index.html"
          })
        )
        .then(({KR}) => KR.onSubmit(resp => {
          let response = JSON.parse(resp['rawClientAnswer']);
          if(response.orderCycle == "CLOSED"){
            // this.closed()
            switch (response.orderStatus) {
              case "PAID":
                this.showPaymentForm = false;
                console.info("showPaymentForm initPayment: ",this.showPaymentForm);
                this.status_pay = "aprobado";
                //si la transaccion permite guardar tarjeta
                if(response.transactions[0].paymentMethodToken){
                  let body={
                    "cardNumber": response.transactions[0].transactionDetails.cardDetails.pan,
                    "userID": this.currentUser.id,
                    "type": response.transactions[0].transactionDetails.cardDetails.effectiveBrand,
                    "token": response.transactions[0].paymentMethodToken
                  };
                  // this.apiService.card_guardar(body).subscribe((resp)=>{
                  //   if(!resp['error']){
                  //     this.instanceSuccessfulPaymentComponent(response);
                  //   }else{
                  //     console.warn("Error guardando tarjeta");
                  //   }
                  // })
                }
                KR.removeForms().then();
                this.elementRef.nativeElement.remove();
                if(this.extraParams){//si el pago viene desde el scaner con un pago
                  this.utilsService.setLocalStorage("initPayment",{
                    amount:response.transactions[0].amount/100,
                    card:response.transactions[0].transactionDetails.cardDetails.pan,
                    date:response.transactions[0].creationDate,
                    checked:false,
                    extraParams:this.extraParams,
                  });
                  // this.apiService.transaction_card({qr:this.extraParams.qr,userID:this.currentUser.id,amount:response.transactions[0].amount/100,cardNumber:response.transactions[0].transactionDetails.cardDetails.pan,cardType:response.transactions[0].transactionDetails.cardDetails.effectiveBrand}).subscribe(()=>{});
                }else{//si el pago es un pago normal de recarga de wallet
                  this.utilsService.setLocalStorage("initPayment",{
                    amount:response.transactions[0].amount/100,
                    card:response.transactions[0].transactionDetails.cardDetails.pan,
                    date:response.transactions[0].creationDate,
                    checked:false
                  });
                  // this.apiService.transaction_wallet({walletID:this.currentUser.wallet.id,userID:this.currentUser.id,amount:response.transactions[0].amount/100,cardNumber:response.transactions[0].transactionDetails.cardDetails.pan,cardType:response.transactions[0].transactionDetails.cardDetails.effectiveBrand}).subscribe(()=>{});
                }
                // window.location.reload();
                let initPayment=this.utilsService.getLocalStorage("initPayment");
                console.info("initPayment: ",initPayment);
                console.info("response: ",response);
                // this.sweetalertsService.generalSuccess("Pago aprobado");
                this.paymentEvent.emit(response);
                break;
              case "UNPAID":
                this.showPaymentForm = false;
                this.status_pay = "rechazado";
                this.sweetalertsService.generalWarning("Pago rechazado");
                break;
              case "RUNNING":
                this.sweetalertsService.generalSuccess("Esperando pago");
                this.status_pay = "procesando";
                break;
              default:
                break;
            }
          }
        }))
        .then(({ KR }) => KR.addForm("#myPaymentForm")
        )
        .then(({ KR, result }) => KR.showForm(result.formId).then(()=>{
          // this.showPaymentForm=true;
        },error => {console.log(error)})
        );
      })
  }
  confirmar(){

  }
}
