<div class="tw-absolute tw-bg-stone-900 tw-z-50 tw-w-full">
    <div class="tw-flex">
        <div class="tw-bg-stone-900 tw-flex">
            <button class="tw-bg-orange-500 tw-flex md:tw-hidden tw-items-center tw-justify-center tw-p-3" (click)="openModalCategoryMenu()"><!--(click)="menuController.toggle();"-->
                <div class="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-white">
                    <i class="fas fa-bars tw-text-orange-600 tw-text-base md:tw-text-lg"></i>
                </div>
            </button>
            <a href=""><img class="tw-h-14 md:tw-h-24 tw-bg-stone-900" src="assets/IMAGENES/LOGO SUBACOM/Group 174.png" alt="" (click)="reload()"></a>
        </div>
        <div class="tw-w-[calc(40vw)] md:tw-w-[calc(100vw-315px)] tw-h-14 md:tw-h-24 tw-flex tw-justify-between tw-shadow-xl tw-bg-opacity-50">
            <div class="tw-p-6 tw-hidden md:tw-flex tw-items-center"><!--(click)="navbarService.navbarOpenSubject$.next(!navbarService.navbarOpenSubject$.getValue())"-->
                <button class="tw-hidden md:tw-flex tw-items-center tw-justify-center tw-p-3">
                    <div class="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-orange-600">
                        <i class="fas fa-bars tw-text-white tw-text-base md:tw-text-2xl"></i>
                    </div>
                    <div class="tw-ml-2 tw-text-left">
                        <p class="tw-text-white tw-mb-0">Menú de</p>
                        <p class="tw-text-white tw-mb-0">Categorias</p>
                    </div>
                </button>
                <div class="tw-p-0" uk-dropdown="animation: uk-animation-slide-top-small; duration: 500; mode: click" id="categoriasMenu" #categoriasMenu>
                    <div>
                        <app-category-menu (closeMenu)="closeCategoryMenu()"></app-category-menu>
                    </div>
                </div>
            </div>
            <!-- <div></div> -->
            <div class="tw-h-full tw-hidden md:tw-flex tw-items-center">
                <div class="tw-flex tw-items-center tw-rounded-full tw-bg-white tw-border tw-border-gray-500 tw-h-16">
                    <form [formGroup]="searchForm" class="tw-flex tw-items-center tw-w-[calc(35vw)]">
                        <input type="text" class="tw-px-2 tw-rounded-full tw-w-full focus:tw-outline-none tw-text-2xl" formControlName="search" (keyup.enter)="search()" placeholder="Busca tu producto en SUBACOM.">
                        <button class="md:tw-flex tw-items-center tw-justify-center" (click)="search()" [disabled]="!searchForm.valid">
                            <div class="tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-orange-600">
                                <i class="fas fa-search tw-text-white tw-text-base md:tw-text-2xl"></i>
                            </div>
                        </button>
                    </form>
                </div>
            </div>
            <div class="tw-flex tw-items-center tw-pr-6">
                <!-- <div class="tw-p-6 tw-flex md:tw-hidden tw-items-center tw-justify-center" (click)="openModalSearch()"><i class="fas fa-search tw-text-gray-700 tw-text-base md:tw-text-2xl"></i></div> -->
                <button class="tw-flex md:tw-hidden tw-items-center tw-justify-center tw-p-3">
                    <div class="tw-w-8 tw-h-8 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-orange-600">
                        <i class="fas fa-user tw-text-white tw-text-base md:tw-text-lg"></i>
                    </div>
                </button>
                <div *ngIf="currentUser" uk-drop="mode: click;; pos: top-right; offset: -30, 0">
                    <div class="tw-flex tw-justify-center">
                        <div class="tw-bg-white tw-w-3/4 tw-shadow-md tw-border tw-border-gray-300">
                            <div class="tw-flex tw-items-center tw-justify-start tw-py-2 hover:tw-bg-gray-100 tw-pl-6" routerLink="/main/profile"><i class="fas fa-user tw-text-gray-400"></i><p class="tw-my-2 tw-px-6">Mi perfil</p></div>
                            <div class="tw-flex tw-items-center tw-justify-start tw-py-2 hover:tw-bg-gray-100 tw-pl-6" routerLink="/main/profile/misfavoritos"><i class="fas fa-heart tw-text-gray-400"></i><p class="tw-my-2 tw-px-6">Mis favoritos</p></div>
                            <div class="tw-flex tw-items-center tw-justify-start tw-py-2 hover:tw-bg-gray-100 tw-pl-6" routerLink="/main/profile/historial"><i class="fas fa-calendar tw-text-gray-400"></i><p class="tw-my-2 tw-px-6">Historial de ventas</p></div>
                            <div class="hover:tw-bg-gray-100 tw-pl-6">
                                <a class="tw-py-2 tw-flex tw-items-center tw-justify-start tw-no-underline tw-text-gray-600" href="https://distribucion.subacom.com.pe/" target="_blank">
                                    <i class="fas fa-plane-departure tw-text-gray-400"></i><p class="tw-my-2 tw-px-5">Distribucion</p>
                                </a>
                            </div>
                            <div class="tw-flex tw-items-center tw-justify-start tw-py-2 hover:tw-bg-gray-100 tw-pl-6" (click)="userService.removeCurrentUser()"><i class="fas fa-sign-out-alt tw-text-gray-400"></i><p class="tw-my-2 tw-px-6">Salir</p></div>
                        </div>
                    </div>
                </div>
                <button class="tw-p-3 tw-w-8 tw-h-8 tw-flex md:tw-hidden tw-items-center tw-justify-center tw-relative tw-rounded-full tw-bg-orange-600" (click)="openModalCart()">
                    <i class="fas fa-shopping-cart tw-text-white tw-text-base md:tw-text-base"></i>
                    <div *ngIf="currentCart" class="tw-absolute tw-rounded-full tw-py-0.5 tw-px-1 tw-text-xs tw-text-white tw-bg-amber-500 counter -tw-right-2 -tw-top-2">{{currentCart.length}}</div>
                </button>
                <!-- <button class="tw-flex tw-items-center tw-justify-center md:tw-pr-4"><img class="tw-w-8 md:tw-w-10 tw-m-auto tw-bg-blue-950 tw-rounded-full tw-p-2" src="assets/logo.png" alt=""></button> -->
                <button class="tw-hidden md:tw-flex tw-items-center tw-justify-center tw-p-3" (click)="currentUser?'':router.navigate(['/login'])">
                    <div class="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-orange-600">
                        <i class="fas fa-user tw-text-white tw-text-base md:tw-text-2xl"></i>
                    </div>
                    <div class="tw-ml-2">
                        <p *ngIf="currentUser" class="tw-text-white tw-mb-1 tw-font-bold">Hola,</p>
                        <p *ngIf="currentUser" class="tw-text-white tw-mb-0 tw-text-sm">{{currentUser.name}}</p>
                        <p *ngIf="!currentUser" class="tw-text-white tw-mb-0">Iniciar sesión</p>
                    </div>
                    <div class="tw-ml-2">
                        <i class="fas fa-chevron-down tw-text-white tw-font-bold"></i>
                    </div>
                </button>
                <div *ngIf="currentUser" uk-drop="mode: click;; pos: top-right; offset: -30, 0">
                    <div class="tw-flex tw-justify-center">
                        <div class="tw-bg-white tw-w-3/4 tw-shadow-md tw-border tw-border-gray-300">
                            <div class="tw-flex tw-items-center tw-justify-start tw-py-2 hover:tw-bg-gray-100 tw-pl-6" routerLink="/main/profile"><i class="fas fa-user tw-text-gray-400"></i><p class="tw-my-2 tw-px-6">Mi perfil</p></div>
                            <div class="tw-flex tw-items-center tw-justify-start tw-py-2 hover:tw-bg-gray-100 tw-pl-6" routerLink="/main/profile/misfavoritos"><i class="fas fa-heart tw-text-gray-400"></i><p class="tw-my-2 tw-px-6">Mis favoritos</p></div>
                            <div class="tw-flex tw-items-center tw-justify-start tw-py-2 hover:tw-bg-gray-100 tw-pl-6" routerLink="/main/profile/historial"><i class="fas fa-calendar tw-text-gray-400"></i><p class="tw-my-2 tw-px-6">Historial de ventas</p></div>
                            <div class="hover:tw-bg-gray-100 tw-pl-6">
                                <a class="tw-py-2 tw-flex tw-items-center tw-justify-start tw-no-underline tw-text-gray-600" href="https://distribucion.subacom.com.pe/" target="_blank">
                                    <i class="fas fa-plane-departure tw-text-gray-400"></i><p class="tw-my-2 tw-px-5">Distribucion</p>
                                </a>
                            </div>
                            <div class="tw-flex tw-items-center tw-justify-start tw-py-2 hover:tw-bg-gray-100 tw-pl-6" (click)="userService.removeCurrentUser()"><i class="fas fa-sign-out-alt tw-text-gray-400"></i><p class="tw-my-2 tw-px-6">Salir</p></div>
                        </div>
                    </div>
                </div>
                <button class="tw-hidden md:tw-flex tw-items-center tw-justify-center tw-p-3" routerLink="/main/arma-tu-pc">
                    <div class="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-orange-600">
                        <i class="fas fas fa-laptop-house tw-text-white tw-text-base md:tw-text-2xl"></i>
                    </div>
                    <div class="tw-ml-2">
                        <p class="tw-text-white tw-mb-0">Arma tu PC</p>
                    </div>
                </button>
                <button class="tw-hidden tw-p-3 tw-w-12 tw-h-12 md:tw-flex tw-items-center tw-justify-center tw-relative tw-rounded-full tw-bg-orange-600">
                    <i class="fas fa-shopping-cart tw-text-white tw-text-base md:tw-text-lg"></i>
                    <div *ngIf="currentCart" class="tw-absolute tw-rounded-full tw-py-1 tw-px-2 tw-text-sm tw-text-white tw-bg-amber-500 counter -tw-right-2 -tw-top-3">{{currentCart.length}}</div>
                </button>
                <div class="tw-right-[calc(70px)] tw-w-[calc(35vw)]" uk-drop="mode: click;; pos: top-left; offset: -30, 0; animation: slide-top; animate-out: true">
                    <div>
                        <app-cart></app-cart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tw-flex md:tw-hidden tw-items-center tw-justify-center tw-h-14">
        <div class="tw-flex tw-items-center tw-rounded-full tw-bg-white tw-border tw-border-gray-500 tw-h-10">
            <form [formGroup]="searchForm" class="tw-flex tw-items-center tw-w-[calc(95vw)]">
                <input type="text" class="tw-px-2 tw-rounded-full tw-w-full focus:tw-outline-none" formControlName="search" (keyup.enter)="search()" placeholder="Busca tu producto en SUBACOM.">
                <button class="md:tw-flex tw-items-center tw-justify-center" (click)="search()">
                    <div class="tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-orange-600">
                        <i class="fas fa-search tw-text-white tw-text-base md:tw-text-lg"></i>
                    </div>
                </button>
            </form>
        </div>
    </div>
</div>