import { Component } from '@angular/core';
import { ConsoleToggleService } from './services/console-toggle.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'ecommercesimplex';
  constructor(
    private consoleToggleService: ConsoleToggleService
  ){
    this.consoleToggleService.disableConsoleInProduction();
  }
}
