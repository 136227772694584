// import { numberText } from './numberText';
import moment from 'moment';
import * as bcrypt from 'bcryptjs';

export class Utils {
    static filePathFirebase(){
        return moment().format("YYYY")+moment().format("MM");
    }
    static jsonToFormData(item){
        var form_data = new FormData();
        for (var key in item ) {
            if(item[key]!=null){
                form_data.append(key, item[key]);
            }
        }
        return form_data;
    }
    static jsonToFormDataV2(item,serverID?){
        var form_data = new FormData();
        for (var key in item ) {
            if(item[key]!=undefined && item[key]!=null && item[key]!=""){
                form_data.append(key, item[key]);
            }
        }
        serverID?form_data.append('serverID', Utils.___getItemLS('currentUserSession')[0].server_id ):'';
        return form_data;
    }
    static toMap(values,key){
        let myMap = new Map();
        values.forEach(element => {
            myMap.set(element[key],element);
        });
        return myMap;
    }
    static ___getItemLS(name){
        if(localStorage.getItem(name)){
          return JSON.parse(localStorage.getItem(name));
        }else{
          return undefined;
        }
    }
    static isMobile() {
        return window && window.matchMedia('(max-width: 767px)').matches;
    }
    static ngbDateToDate(ngbDate: { month, day, year }) {
        if (!ngbDate) {
            return null;
        }
        return new Date(`${ngbDate.month}/${ngbDate.day}/${ngbDate.year}`);
    }
    static dateToNgbDate(date: Date) {
        if (!date) {
            return null;
        }
        date = new Date(date);
        return { month: date.getMonth() + 1, day: date.getDate(), year: date.getFullYear() };
    }
    static scrollToTop(selector: string) {
        if (document) {
            const element = <HTMLElement>document.querySelector(selector);
            element.scrollTop = 0;
        }
    }
    static genId() {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    static validateEmail(email){ 
        var re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/; 
        return re.test(email); 
    }
    static validateOnlyNumbers(phone){ 
        var re = /^[0-9]*$/; 
        return re.test(phone); 
    }
    static validatePhone(phone){ 
        var re = /^[0-9]*$/; 
        return re.test(phone); 
    }
    static validateDateDDMMYYYY(date){ 
        var re = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/; 
        return re.test(date); 
    }
    static validateDecimal(decimal){
        var re = /^\d+(\.\d+)*$/;
        return re.test(decimal); 
    }
    static validateMoneda(moneda){ 
        switch(moneda){
            case 'PEN':{
                return {validate:true,code:'PEN'};
            }
            case 'USD':{
                return {validate:true,code:'USD'};
            }
            default:{
                return {validate:false,code:''};
            }
        }
    }
    static validateCanal(canal){ 
        switch(canal){
            case 'whatsapp':{
                return {validate:true,code:'WA'};
            }
            case 'email':{
                return {validate:true,code:'EM'};
            }
            case 'facebook':{
                return {validate:true,code:'FB'};
            }
            case 'twitter':{
                return {validate:true,code:'TW'};
            }
            case 'llamada':{
                return {validate:true,code:'TL'};
            }
            case 'web':{
                return {validate:true,code:'WE'};
            }
            default:{
                return {validate:false,code:''};
            }
        }
    }
    static formatddmmyyyy(fec){
        let day=fec.substring(2,8);
        let month=fec.substring(2,5);
        let year=fec.substring(0,4);
        console.info("DESDE HACIA: ",day," ",month," ",year);
        return day+'-'+month+'-'+year;
    }
    //FORMAT DATES
    static dateTimeFormValue(fec?){ 
        var date = fec?new Date(fec):new Date();
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0,16);
    }
    static dateTimeFormValueWithoutSec(fec?){ 
        var date = fec?new Date(fec):new Date();
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0,13);
    }
    static dateFormValue(fec?){ 
        var date = fec?new Date(fec):new Date();
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0,10);
    }
    static dateFormValueyyyymmdd(fec){ 
        var date = new Date(fec.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3"));
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0,10);
    }
    static dateMomentddmmyyyyhhmm(fec){ 
        return moment(fec).format('DD/MM/YYYY h:mm a');
    }
    static dateFormValueddmmyyyy(fec){ 
        console.info("dateFormValueddmmyyyy: ",fec);
        var date = new Date(fec.replace( /(\d{2})\/(\d{2})\/(\d{4})/, "$3/$1/$2"));
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date.toISOString().slice(0,10);
    }
    static numberFormatComma(num){
        if(num!=undefined){
            var num_parts = num.toString().split(".");
            num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return num_parts.join(".");
        }else{
            return 0; 
        }
    }
    static percentage(num,den){
        if(den>0){
            return (num/den)*100;
        }
        else{
            return 0;
        }
    }
    static getFileExtension(filewithextension){
        var re = /(?:\.([^.]+))?$/;
        return re.exec(filewithextension)[1];
    }
    static getFileType(filetype){
        let exist = filetype.indexOf('image');
        return exist==-1?false:true;
    }
    static base64ToFile(data, filename) {
        const arr = data.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while(n--){
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    static getNested(outJSON, key){
        var groupBy = function(xs, key) {
          return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        };
        return groupBy(outJSON, key);
    }
    static parseAddress(address){
        var index=address.split("-");
        console.info("parseAddress: ",index);
        let distrito = index[index.length-1].trim();
        let ciudad = index[index.length-2].trim();
        let index2 = index[index.length-2].trim().split('/\s');
        let region = index2[index2.length-1].trim();
        let ubigeo = region+' - '+ciudad+' - '+distrito;
        let indice = address.indexOf(ubigeo);
        let direccion = address.substring(0,indice);
        console.info("distrito: ",distrito);
        console.info("ciudad: ",ciudad);
        console.info("region: ",region);
        console.info("direccion: ",direccion);
        return {ubigeo:ubigeo,distrito:distrito,ciudad:ciudad,region:region,direccion:direccion};
    }
    static replaceWithBolds(text){
        let negritacursiva = text.replace(/\*\_([,;.0-9a-zA-ZÀ-ÿ\u00f1\u00d1\u0040\u0020\u002D]*?)\_\*/g,"<b><em>$1<\/em><\/b>");
        let cursivanegrita = negritacursiva.replace(/\_\*([,;.0-9a-zA-ZÀ-ÿ\u00f1\u00d1\u0040\u0020\u002D]*?)\*\_/g,"<b><em>$1<\/em><\/b>");
        let curvisa = cursivanegrita.replace(/\_([,;.0-9a-zA-ZÀ-ÿ\u00f1\u00d1\u0040\u0020\u002D]*?)\_/g,"<em>$1<\/em>");
        return Utils.replaceLineJump(curvisa.replace(/\*([,;.0-9a-zA-ZÀ-ÿ\u00f1\u00d1\u0040\u0020\u002D]*?)\*/g,"<b>$1<\/b>"));
    }
    static replaceLineJump(content){
        let content1 = content.replace(/\r\n/g, "<br />");
        let content2 = content1.replace(/\n\r/g, "<br />");
        let content3 = content2.replace(/\n/g, "<br />");
        let content4 = content3.replace(/\r/g, "<br />");
        return content4;
    }
    static iterateFormMissingValidators(form){
        const invalid = [];
        const controls = form.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.warn("iterateFormMissingValidators: ",invalid);
        return invalid;
    }
    static parseFloat(num){
        if(num){
            return parseFloat(num.replace(/,/g, ''));
        }else{
            return 0;
        }
    }
    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    static divOverZero(num){
        return num?num:0;
    }
    static select(array,itemToSelect){
        for (const item of array) {
            item.selected = item === itemToSelect;
        }
    }
    static generarNumeroAleatorio(){
        const min = 1000; // El número mínimo de 4 dígitos
        const max = 9999; // El número máximo de 4 dígitos
        const numeroAleatorio = Math.floor(Math.random() * (max - min + 1)) + min;
        return String(numeroAleatorio);
    };
    // Función para encriptar una contraseña
    static encryptPassword(password: string): string {
        const salt = bcrypt.genSaltSync(10);
        return bcrypt.hashSync(password, salt);
    }
    
    // Función para verificar si una contraseña coincide con su hash encriptado
    static comparePasswords(inputPassword: string, hashedPassword: string): boolean {
        return bcrypt.compareSync(inputPassword, hashedPassword);
    } 

    static jsonToParams(paramsObject: any): string {
        const paramsArray = [];
        for (const key in paramsObject) {
          if (paramsObject.hasOwnProperty(key)) {
            let paramValue = paramsObject[key];
            if (Array.isArray(paramValue)) {
              paramValue = paramValue.map(value => encodeURIComponent(value)).join(',');
            } else {
              paramValue = encodeURIComponent(paramValue);
            }
            const encodedKey = encodeURIComponent(key);
            paramsArray.push(`${encodedKey}=${paramValue}`);
          }
        }
        return paramsArray.join('&');
      }
}