import { Injectable } from '@angular/core';
import { SweetalertsService } from './sweetalerts.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';


@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(
    private storage:AngularFireStorage,
    public sweetalertsService: SweetalertsService,
  ) { }
  
  public uploadFileCloudStorage(path, nombreArchivo, datos){
    return this.storage.upload(path+'/'+nombreArchivo, datos);
  }
  public referenciaCloudStorage(nombreArchivo){
    return this.storage.ref(nombreArchivo);
  }
  addFile(file, filePath){
    return new Promise((resolve)=>{
      this.uploadFile(file,filePath).then((publicURL:any)=>{ 
        resolve(publicURL);
      })
    })
  }
  async addFiles(files) {
    const uploadPromises = files.map((file) => this.uploadFile(file,file.name));
    const publicURLs = await Promise.all(uploadPromises);
    return publicURLs;
  }
  public uploadFile(file,path){
    return new Promise((resolve)=>{
      let fileContainer = new FormData();
      fileContainer.delete("file");
      fileContainer.append("file", file, file.name);
      let referencia = this.referenciaCloudStorage(path+'/'+fileContainer.get('file')['name']);
      let request = this.uploadFileCloudStorage(path,fileContainer.get('file')['name'], fileContainer.get('file'));
      request.percentageChanges().subscribe((porcentaje)=>{
        console.info("PORCENTAJE ARCHIVOS: ",Math.round(porcentaje));
      },(err)=>{
        this.sweetalertsService.generalError("Error cargando archivo.",);
      },
      ()=>{
        referencia.getDownloadURL().subscribe((publicUrl)=>{
          resolve(publicUrl);
        })
      })
    })
  }
  public uploadBlob(blob,path){
    return new Promise((resolve)=>{
      let randomName="campania_"+(new Date().toISOString());
      let fileContainer = new FormData();
      fileContainer.delete("file");
      fileContainer.append("file", blob, randomName);
      let referencia = this.referenciaCloudStorage(path+'/'+randomName);
      let request = this.uploadFileCloudStorage(path,randomName, fileContainer.get('file'));
      request.percentageChanges().subscribe((porcentaje)=>{
        console.info("PORCENTAJE ARCHIVOS: ",Math.round(porcentaje));
      },(err)=>{
        this.sweetalertsService.generalError("Error cargando archivo.",);
      },
      ()=>{
        referencia.getDownloadURL().subscribe((publicUrl)=>{
          resolve(publicUrl);
        })
      })
    })
  }
}