<!-- <div id="contenido" #contenido class="tw-pt-44 md:tw-pt-0 tw-overflow-auto tw-h-[calc(100vh)]"> -->
    <!-- <div class="tw-px-16 tw-pt-2 md:tw-pt-44 tw-z-0 tw-block">
        <nav aria-label="Breadcrumb">
          <ul class="uk-breadcrumb">
            <li><a routerLink="/main/home">Inicio</a></li>
            <li><a routerLink="/main/profile">Cuenta</a></li>
            <li><a routerLink="/main/profile/seguridad">Direcciones</a></li>
          </ul>
        </nav>
    </div>
    <div class="tw-px-2 md:tw-px-16 md:tw-min-h-[calc(100vh-216px)]">
        <div class="tw-flex tw-w-full tw-justify-start">
          <div class="tw-border-b-2 tw-border-amber-500 tw-pb-1 tw-inline-block tw-w-auto tw-my-5">
            <span class="tw-text-stone-900 tw-text-lg tw-font-bold">Direcciones</span>
          </div>
        </div>
        <div class="tw-px-2 md:tw-px-56 tw-pb-8">
            <div class="tw-mb-20">
                <ng-container *ngIf="step==2; else listAddress"></ng-container>
                <ng-container *ngIf="step==1; else formAddress"></ng-container>
            </div>
        </div>
    </div>
    <app-footer></app-footer> -->
    <ng-container *ngIf="step==2; else listAddress"></ng-container>
    <ng-container *ngIf="step==1; else formAddress"></ng-container>
<!-- </div> -->
<ng-template #formAddress>
    <form [formGroup]="form">
        <div class="tw-grid tw-grid-cols-2 tw-gap-1 tw-border-b tw-border-gray-300 tw-pb-2">
            <div class="tw-col-span-2 md:tw-col-span-1">
                <p class="tw-text-gray-600 tw-my-2 md:tw-my-3 tw-font-bold">Departamento</p>
                <select class="tw-rounded-md tw-w-full tw-text-lg tw-p-1.5 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none"  formControlName="departamento" (change)="onSelectDepartamento($event)">
                    <option *ngFor="let item of department_list" [value]="item.id">{{item.name}}</option>
                </select>
            </div>
            <div class="tw-col-span-2 md:tw-col-span-1">
                <p class="tw-text-gray-600 tw-my-2 md:tw-my-3 tw-font-bold">Provincia</p>
                <select class="tw-rounded-md tw-w-full tw-text-lg tw-p-1.5 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none"  formControlName="provincia" (change)="onSelectProvincia($event)">
                    <option *ngFor="let item of province_list" [value]="item.id">{{item.name}}</option>
                </select>
            </div>
            <div class="tw-col-span-2 md:tw-col-span-1">
                <p class="tw-text-gray-600 tw-my-2 md:tw-my-3 tw-font-bold">Distrito</p>
                <select class="tw-rounded-md tw-w-full tw-text-lg tw-p-1.5 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none"  formControlName="distrito" (change)="onSelectDistrito($event)">
                    <option *ngFor="let item of district_list" [value]="item.id">{{item.name}}</option>
                </select>
            </div>
            <div class="tw-col-span-2">
                <p class="tw-text-gray-600 tw-my-2 md:tw-my-3 tw-font-bold">Dirección</p>
                <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="text" formControlName="address">
            </div>
        </div>
        <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-mt-4 tw-w-full md:tw-w-1/2">
            <div class="tw-col-span-1">
                <ion-button fill="outline" color="orange" class="tw-my-0.5 md:tw-my-2 tw-w-full tw-rounded-md tw-h-10 tw-normal-case" (click)="step=1"> Cancelar</ion-button>
            </div>
            <div class="tw-col-span-1">
                <ion-button fill="clear" class="tw-text-white tw-bg-orange-600 tw-my-0.5 md:tw-my-2 tw-w-full tw-rounded-md tw-h-10 tw-normal-case" [disabled]="!form.valid" (click)="save()"> Guardar</ion-button>
            </div>
        </div>
    </form>
</ng-template>
<ng-template #listAddress>
    <div>
        <div class="tw-h-32 tw-w-full tw-flex tw-justify-center tw-items-center" *ngIf="direcciones_list?.length==0">
            <div class="tw-text-center">
                <i class="far fa-sad-tear tw-text-orange-600 tw-font-bold tw-text-center tw-text-3xl"></i>
                <p class="tw-text-orange-600 tw-font-bold tw-text-center">Aún no tienes direcciones reigstradas</p>
            </div>
        </div>
        <div *ngFor="let item of direcciones_list" class="tw-flex tw-justify-between tw-items-center tw-border-b tw-border-gray-300 tw-pt-2">
            <div class="tw-flex tw-justify-center tw-items-center tw-ml-2 md:tw-ml-8" *ngIf="showSelectedAddress" (click)="selectedAddress=item;selectedAddressEventEmit(item)">
                <button *ngIf="selectedAddress.id!=item.id"><i class="fa-regular fa-circle tw-text-xl"></i></button>
                <button *ngIf="selectedAddress.id==item.id"><i class="fa-regular fa-circle-check tw-text-xl tw-font-bold tw-text-green-600"></i></button>
            </div>
            <div>
                <p class="tw-text-gray-800 tw-font-bold">{{item.departamentoText}} - {{item.provinciaText}} - {{item.distritoText}}</p>
                <p class="tw-line-clamp-2 tw-text-xs tw-text-gray-600">{{item.address}}</p>
            </div>
            <div class="tw-flex tw-justify-around tw-items-center">
                <!-- <button>
                    <div class="tw-border tw-border-gray-600 tw-rounded-md tw-p-2 tw-mx-3"><i class="fas fa-edit"></i></div>
                </button> -->
                <button (click)="update(item)"><div class="tw-border tw-border-gray-600 tw-rounded-md tw-p-2 tw-mx-3"><i class="fas fa-trash"></i></div></button>
            </div>
        </div>
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2 tw-mt-4 tw-w-3/4">
            <div>
                <ion-button fill="outline" color="orange" class="tw-my-0.5 md:tw-my-2 tw-w-full tw-rounded-md tw-h-10 tw-normal-case" (click)="step=2"> <i class="fas fa-plus"></i>&nbsp;&nbsp;Agregar Domicilio</ion-button>
            </div>
        </div>
    </div>
</ng-template>