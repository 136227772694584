<div *ngIf="currentUser && currentUser.id!='1030526'" class="tw-py-3 tw-px-6">
    <p class="tw-my-1"><i class="fas fa-user tw-text-amber-600 tw-text-lg"></i>&nbsp;&nbsp;{{currentUser.contact}}</p>
    <p class="tw-my-1"><i class="fas fa-envelope tw-text-amber-600 tw-text-lg"></i>&nbsp;&nbsp;{{currentUser.email}}</p>
    <!-- <div>
        <ion-button fill="clear" class="tw-text-white tw-bg-blue-950 tw-my-6 tw-py-2 tw-w-full tw-rounded-full" (click)="next.emit(1)">Siguiente</ion-button>
    </div> -->
    <div class="tw-w-full tw-flex tw-justify-end">
        <ion-button id="irCompra" fill="clear" class="tw-text-white tw-bg-orange-600 tw-my-0.5 md:tw-my-2 tw-rounded-sm tw-h-10 tw-normal-case tw-w-1/2 md:tw-w-1/3" (click)="next.emit(1)"> Continuar&nbsp;&nbsp;<i class="fas fa-arrow-right tw-text-white tw-text-lg tw-font-bold"></i></ion-button>
    </div>
</div>
<div *ngIf="!(currentUser && currentUser.id!='1030526')">
    <div [ngClass]="signinStep==3?'tw-block':'tw-hidden'" class="tw-overflow-auto  tw-grid tw-grid-cols-2 tw-gap-4 tw-px-5 tw-py-5 md:tw-px-14">
        <div class="tw-col-span-2">
            <div class="tw-justify-start">
                <form [formGroup]="loginForm">
                    <img class="tw-h-12 tw-m-auto" src="assets/IMAGENES/LOGO SUBACOM/logo naranja.png" alt="">
                    <p class="tw-text-gray-600 tw-font-bold tw-text-center tw-text-base md:tw-text-2xl tw-mt-6 tw-mb-1">Inicio de sesión</p>
                    <p class="tw-text-gray-600 tw-text-center tw-mt-2 tw-mb-6 tw-text-xs md:tw-text-base">Inicia sesión para continuar con tu compra</p>
                    <p class="tw-text-gray-600 tw-my-2 md:tw-my-3">Usuario</p>
                    <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400" type="text" formControlName="email">
                    <p class="tw-text-gray-600 tw-my-2 md:tw-my-3">Contraseña</p>
                    <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400" type="password" formControlName="password">
                    <ion-button fill="clear" class="tw-text-white tw-bg-orange-600 tw-my-2 tw-w-full tw-rounded-md tw-h-10 tw-mt-6 tw-normal-case" [disabled]="!loginForm.valid" (click)="login()"> Iniciar Sesión</ion-button>
                    <!-- <ion-button fill="outline" color="orange" class="tw-my-2 tw-w-full tw-rounded-md tw-h-10 tw-normal-case" routerLink="/signin"> Registrarme</ion-button>
                    <div class="tw-border-t tw-border-t-gray-400 tw-mt-4 tw-flex tw-justify-between">
                        <button class="tw-text-gray-600 tw-text-center tw-text-sm tw-w-full" (click)="loginGuest()">Ingresar como invitado</button>
                        <button class="tw-text-gray-600 tw-text-center tw-text-sm tw-w-full tw-py-2" routerLink="/reset-pass">Recuperar contraseña</button>
                    </div> -->
                </form>
            </div>
        </div>
        <!-- <div class="tw-col-span-2 tw-text-center">
            <button id="irCompra" class="tw-bg-blue-950 tw-text-white tw-w-3/4 tw-font-bold tw-rounded-full tw-h-8 tw-px-3 tw-mx-1 tw-border tw-border-blue-950" (click)="login()">Iniciar sesión&nbsp;<i class="fas fa-arrow-right tw-text-white tw-text-lg tw-font-bold"></i></button>
            <button class="tw-text-center tw-text-sm tw-w-full tw-my-2" (click)="signinStep=1">Registrarse</button>
        </div> -->
    </div>
    <div [ngClass]="signinStep==1?'tw-block':'tw-hidden'" class="uk-accordion-content tw-h-[calc(100vh-144px)] md:tw-h-full tw-overflow-auto md:tw-overflow-hidden">
        <p class="tw-text-xs md:tw-text-base tw-mb-1 md:tw-mb-2">Solicitamos unicamente la información esencial para la finalización de la compra.</p>
        <form [formGroup]="userForm">
            <div class="tw-grid tw-grid-cols-2">
                <div class="tw-col-span-2 md:tw-col-span-1">
                    <div class="tw-justify-start">
                        <p class="tw-text-gray-800 tw-py-2 tw-my-0.5 md:tw-my-1">Tipo de documento</p>
                        <select class="tw-h-9 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-2" formControlName="type_doc">
                            <option value="" disabled selected>Seleccione una opción</option>
                            <option value="D">DNI</option>
                            <option value="R">RUC</option>
                            <option value="P">Pasaporte</option>
                            <option value="O">Otros</option>
                        </select>
                        <p class="tw-text-gray-800 tw-py-2 tw-my-0.5 md:tw-my-1">Nombre / Razon Social</p>
                        <input type="text" class="tw-h-9 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-3" type="text" formControlName="name">
                        <p class="tw-text-gray-800 tw-py-2 tw-my-0.5 md:tw-my-1">Correo electrónico</p>
                        <input type="text" class="tw-h-9 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-3" type="email" formControlName="email">
                        <p class="tw-text-gray-800 tw-py-2 tw-my-0.5 md:tw-my-1">Contraseña</p>
                        <input type="text" class="tw-h-9 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-3" type="password" formControlName="password">
                    </div>
                </div>
                <div class="tw-col-span-2 md:tw-col-span-1">
                    <div class="tw-justify-start">
                        <p class="tw-text-gray-800 tw-py-2 tw-my-0.5 md:tw-my-1">Número de documento</p>
                        <input type="text" class="tw-h-9 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-3" type="text" formControlName="num_doc">
                        <p class="tw-text-gray-800 tw-py-2 tw-my-0.5 md:tw-my-1">Contacto</p>
                        <input type="text" class="tw-h-9 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-3" type="text" formControlName="contact">
                        <p class="tw-text-gray-800 tw-py-2 tw-my-0.5 md:tw-my-1">Teléfono</p>
                        <input type="text" class="tw-h-9 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-3" type="text" formControlName="phone">
                        <p class="tw-text-gray-800 tw-py-2 tw-my-0.5 md:tw-my-1">Confirmar contraseña</p>
                        <input type="text" class="tw-h-9 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-3" type="password" formControlName="confirmPassword">
                    </div>
                </div>
                <div class="tw-col-span-2 tw-text-center tw-mt-3">
                    <button id="irCompra" class="tw-bg-blue-950 tw-text-white tw-w-3/4 tw-font-bold tw-rounded-full tw-h-8 tw-px-3 tw-mx-1 tw-border tw-border-blue-950" (click)="signin()">Registrarse&nbsp;<i class="fas fa-arrow-right tw-text-white tw-text-lg tw-font-bold"></i></button>
                    <button class="tw-text-center tw-text-sm tw-w-full tw-my-2" (click)="signinStep=3">Logearse</button>
                </div>
            </div>
        </form>
    </div>
    <div [ngClass]="signinStep==2?'tw-block':'tw-hidden'" class="tw-overflow-auto  tw-grid tw-grid-cols-2 tw-gap-4 tw-px-5 tw-py-5 md:tw-px-14">
        <div class="tw-col-span-2">
            <div class="tw-justify-start">
                <form [formGroup]="checkForm">
                    <p class="tw-text-white tw-py-2 tw-my-0.5 md:tw-my-3">Ingrese su código de verificación</p>
                    <input class="tw-rounded-full tw-w-full tw-text-lg tw-p-1" type="text" formControlName="pin">
                </form>
            </div>
        </div>
        <div class="tw-col-span-2">
            <button class="tw-text-white tw-bg-amber-500 tw-my-6 tw-py-2 tw-w-full tw-rounded-full" (click)="checkPin()">Enviar</button>
            <button class="tw-text-white tw-text-center tw-text-sm tw-w-full" (click)="sendPin()">Volver a enviar código</button>
        </div>
    </div>
</div>