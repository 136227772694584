import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
declare var UIkit: any;

@Component({
  selector: 'app-modal-cotizacion-process',
  templateUrl: './modal-cotizacion-process.component.html',
  styleUrls: ['./modal-cotizacion-process.component.scss']
})
export class ModalCotizacionProcessComponent {
  formCliente;
  formClienteRuc;
  currentBuild;
  currentBuildAmounts;
  currentUser;
  empresa;
  empresaSubscription;
  activeTab=1;
  constructor(
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    public router: Router,
    private navParams: NavParams,
    public userService : UserService,
  ){
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
      // console.warn("currentUser: ",this.currentUser);
    })
    this.formCliente = this.formBuilder.group({
      name: ['', Validators.required],
      lastname: ['', Validators.required],
      email: [''],
      phone: ['', Validators.required],
      type_doc: [''],
      num_doc: ['', Validators.required],
    });
    this.formClienteRuc = this.formBuilder.group({
      ruc: ['', Validators.required],
      razon: ['', Validators.required],
      email: [''],
      direccion: ['', Validators.required],
      phone: ['', Validators.required],
    });
  }
  ngOnInit(){
    this.currentBuild = this.navParams.get('currentBuild');
    this.currentBuildAmounts = this.navParams.get('currentBuildAmounts');
  }
  ionViewDidEnter(){
    // this.formCliente.patchValue(this.currentUser);
  }
  download(type){
    this.router.navigate(['/main/proforma',{
      proforma:JSON.stringify({
        currentBuild:this.currentBuild,
        currentBuildAmounts:this.currentBuildAmounts,
        formCliente:this.formCliente.getRawValue(),
        formClienteRuc:this.formClienteRuc.getRawValue(),
        type:type
      })
    }]);
    this.modalController.dismiss();
  }
}
