<div class="tw-bg-blue-950 tw-w-full tw-flex tw-justify-between tw-items-center">
  <div class="tw-mx-4 tw-flex tw-items-center"><i class="fas fa-search tw-text-lg tw-text-white tw-font-bold"></i>&nbsp;&nbsp;<input type="text" class="tw-h-8 tw-border tw-border-gray-500 tw-rounded-full tw-w-full tw-px-3" (keyup)="filterData()" [(ngModel)]="searchTerm"></div>
  <div class="tw-p-3"><button class="tw-p-3 tw-bg-white tw-rounded-md"><i class="fas fa-sync-alt tw-text-xl tw-font-bold"></i></button></div>
</div>

<table mat-table [dataSource]="tableData">
  <ng-container [matColumnDef]="'acciones'">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <ion-button fill="clear" class="tw-text-gray-600" (click)="clickAction(element)" title="Detalle">
        <i class="fas fa-expand-arrows-alt"></i>
      </ion-button>
    </td>
  </ng-container>
  <!-- Columnas dinámicas -->
  <ng-container *ngFor="let column of tableColumns" [matColumnDef]="column.key">
    <th mat-header-cell *matHeaderCellDef>{{ column.label }}</th>
    <td mat-cell *matCellDef="let element">{{ element[column.key] }}</td>
  </ng-container>
  <!-- Columna para los datos desconocidos -->
  <ng-container matColumnDef="unknownData">
    <th mat-header-cell *matHeaderCellDef> Datos desconocidos </th>
    <td mat-cell *matCellDef="let element"> No disponible </td>
  </ng-container>
  <!-- Agregar las columnas dinámicas a la tabla -->
  <tr mat-header-row *matHeaderRowDef="getColumnKeys()"></tr>
  <tr mat-row *matRowDef="let row; columns: getColumnKeys() else unknownData"></tr>
</table>

<mat-paginator [length]="filteredData.length" [pageSize]="pageSize" [pageSizeOptions]="[10]" (page)="pageIndex = $event.pageIndex"></mat-paginator>