<ion-header>
    <ion-toolbar>
        <ion-title class="tw-mt-1">{{title}}</ion-title>
        <div class="tw-p-2">
            <ion-searchbar #ionSearchbar id="ionSearchbar" [value]="searchValue" class="tw-border tw-border-gray-600 tw-rounded-full customSearch" placeholder="Buscar"></ion-searchbar>
        </div>
    </ion-toolbar>
  </ion-header>
  <ion-content class="tw-h-72">
    <div class="tw-col-span-1 tw-px-2">
        <div>
            <div *ngFor="let item of content | filterBy: ['name']: ionSearchbar.value">
                <div class="tw-flex tw-p-1"><div class="tw-rounded-md tw-border tw-border-gray-600 tw-w-7 tw-flex tw-items-center tw-justify-center" (click)="item.selected=!item.selected"><i *ngIf="item.selected" class="fas fa-check"></i></div> &nbsp;&nbsp;<span>{{item.name}}</span></div>
            </div>
        </div>
    </div>
  </ion-content>
  <ion-footer class="tw-bg-white">
    <div class="tw-flex">
        <button class="tw-w-1/2 tw-text-gray-600 tw-my-3 tw-py-2 tw-rounded-lg tw-h-10" (click)="clear()">Limpiar</button>
        <button class="tw-w-1/2 tw-text-white tw-bg-red-600 tw-my-3 tw-py-2 tw-rounded-lg tw-h-10" (click)="search()">Filtrar</button>
    </div>
  </ion-footer>