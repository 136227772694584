<!-- isBom!=N -->
<div *ngIf="item.isBom!='Y'" class="tw-flex tw-justify-center tw-items-center tw-col-span-1 tw-rounded-xl tw-shadow-lg tw-bg-white tw-min-h-[calc(375px)] md:tw-min-h-[calc(550px)] product-container">
    <div *ngIf="item.prices && item.prices?.list && item.prices?.discount && item.prices?.limit !== item.prices?.list" class="discount-label">
        -{{ ((item.prices?.list - item.prices?.limit) / item.prices?.list * 100) | number:'1.0-0' }}%
    </div>
    <div>
        <div class="tw-p-3">
            <div class="tw-w-full" (mouseenter)="item.showButtons=true" (mouseleave)="item.showButtons=false">
                <a class="tw-relative inline-block">
                    <a class="tw-no-underline" [routerLink]="['/main', item.id, item.name]">
                        <img class="tw-m-auto tw-h-32 md:tw-h-52 tw-object-cover" [src]="item.urlImagen && item.urlImagen.length > 0 ? item.urlImagen[0] : 'assets/notfoundV.png'" onerror="this.src='assets/notfoundV.png'" alt="">
                    </a>
                    <div *ngIf="item.showButtons" class="tw-absolute tw-left-0 tw-right-0 tw-bottom-5 tw-flex tw-justify-center tw-space-x-4">
                      <button (click)="saveFavorite(item)" class="tw-rounded-full tw-h-8 tw-w-8 tw-bg-orange-600 hover:tw-bg-orange-500 tw-flex tw-justify-center tw-items-center tw-transition-width tw-duration-300"><i class="fas fas far fa-heart tw-text-lg tw-text-white tw-font-bold"></i></button>
                      <button (click)="openModalAddProduct(item)" class="tw-rounded-full tw-h-8 tw-w-8 tw-bg-orange-600 hover:tw-bg-orange-500 tw-flex tw-justify-center tw-items-center tw-transition-width tw-duration-300"><i class="fas fas far fa-eye tw-text-lg tw-text-white tw-font-bold"></i></button>
                    </div>
                </a>
            </div>
            <div class="tw-w-full tw-p-1">
                <p class="tw-text-xs md:tw-text-sm tw-my-1 tw-text-slate-900" style="min-height: 48px;"><strong class="tw-text-xs md:tw-text-sm">SKU:</strong>&nbsp;{{item.sku}}</p>
                <p class="tw-text-red-600"><strong>Código: </strong>{{item.value}}</p>
                <ng-container *ngIf="item.stock; else whatsappLink">
                    <p class="tw-text-xs md:tw-text-sm tw-my-1 tw-font-bold tw-text-stone-900">Stock: <strong class="tw-text-green-600 tw-text-xs md:tw-text-sm">
                        {{ item?.stock?.qty <= 10 ? ' Solo ' + item?.stock?.qty : '+' + 10 }} en Stock
                    </strong></p>
                </ng-container>
                <ng-template #whatsappLink>
                    <p class="tw-text-green-600 tw-text-sm md:tw-text-sm tw-my-1"><strong>Contactar&nbsp;</strong>
                    <a [href]="'https://api.whatsapp.com/send?text='+firestoreService.empresaData?.messageStock+' '+item.name+' '+'&phone=' + firestoreService.empresaData?.telefono" target="_blank">
                        <i class="fab fa-whatsapp tw-text-green-600 tw-text-xl"></i>
                    </a>
                    </p>
                </ng-template>
                <a [routerLink]="['/main', item.id, item.name]" class="tw-no-underline"><p class="tw-h-24 tw-text-sm md:tw-text-sm tw-font-bold tw-line-clamp-5 tw-mb-1 tw-text-black hover:tw-text-blue-950" style="min-height: 96px;">{{item.name}}</p></a>
            </div>
        </div>
        <div *ngIf="item.prices?.ofert=='0.00'" class="tw-min-h-20">
            <p class="tw-text-gray-400 tw-text-base tw-mb-0 tw-line-through tw-block tw-ml-2"><strong>S/{{item.prices?.list||''| number:'1.2-2'}}</strong></p>
            <div class="tw-flex tw-items-center"><p class="tw-text-orange-600 tw-text-xl tw-mb-0 tw-ml-2"><strong>S/{{item.prices?.discount||''| number:'1.2-2'}}</strong></p></div>
        </div>
        <div *ngIf="item.prices?.ofert!='0.00'" class="tw-min-h-20">
            <p class="tw-text-gray-400 tw-text-base tw-mb-0 tw-line-through tw-block tw-ml-2"><strong>S/{{item.prices?.list||''| number:'1.2-2'}}</strong></p>
            <p class="tw-text-gray-400 tw-text-lg tw-mb-0 tw-ml-2"><strong>S/{{item.prices?.discount||''| number:'1.2-2'}}</strong></p>
            <div class="tw-flex tw-items-center"><p class="tw-text-orange-600 tw-text-xl tw-mb-0 tw-ml-2"><strong>S/{{item.prices?.ofert||''| number:'1.2-2'}}</strong></p>&nbsp;<img class="tw-w-8" src="assets/minisubacom.png" alt=""></div>
        </div>
        <!-- <div class="tw-flex tw-items-center tw-justify-center">
            <ng-container *ngFor="let i of [].constructor(5); let index = index">
              <div class="tw-px-0.5">
                <i [ngClass]="index < item.ranking ? 'fas fa-star tw-text-amber-500 tw-text-xs md:tw-text-lg' : 'far fa-star tw-text-amber-500 tw-text-xs md:tw-text-lg'"></i>
              </div>
            </ng-container>
        </div> -->
        <!-- <div class="tw-flex tw-items-center tw-justify-center tw-py-2 md:tw-py-6">
            <button class="tw-hidden tw-rounded-full tw-h-12 tw-w-12 hover:tw-w-44 tw-bg-orange-600 hover:tw-bg-orange-500 md:tw-flex tw-justify-center tw-items-center tw-transition-width tw-duration-300 addCart" (click)="addProductToCart(item)"><i class="fas fas fa-shopping-cart tw-text-xl tw-text-white tw-font-bold"></i></button>
            <button class="md:tw-hidden tw-rounded-full tw-h-6 tw-bg-orange-600 tw-flex tw-justify-center tw-items-center tw-text-xs tw-px-2" (click)="addProductToCart(item)"><i class="fas fa-shopping-cart tw-text-xs tw-text-white tw-font-bold"></i><span class="tw-ml-1 tw-text-white tw-font-bold">Añadir al carrito</span></button>
        </div> -->
        <div class="tw-grid tw-grid-cols-2 tw-py-2 md:tw-py-6 tw-gap-1">
            <button class="tw-col-span-2 md:tw-col-span-1 tw-rounded-full tw-h-8 tw-bg-orange-600 tw-flex tw-justify-center tw-items-center tw-text-xs tw-px-2" (click)="addProductToCart(item)"><i class="fas fa-shopping-cart tw-text-xs tw-text-white tw-font-bold"></i><span class="tw-ml-1 tw-text-white tw-font-bold">Añadir al carrito</span></button>
            <button class="tw-col-span-2 md:tw-col-span-1 tw-rounded-full tw-h-8 tw-bg-orange-600 tw-flex tw-justify-center tw-items-center tw-text-xs tw-px-2" (click)="addProductToCartDirect(item)"><span class="tw-ml-1 tw-text-white tw-font-bold">Comprar</span></button>
        </div>
    </div>
</div>


<!-- isBom==Y -->
<div *ngIf="item.isBom=='Y'" class="tw-col-span-1 tw-rounded-xl tw-shadow-lg tw-bg-white">
    <div class="tw-p-4 tw-pb-0 tw-min-h-[calc(210px)]">
        <div class="tw-w-full tw-m-auto">
            <a (click)="openModalAddProduct(item)"><img class="tw-h-44 tw-object-cover tw-m-auto" [src]="item.urlImagen" onerror="this.src='assets/notfoundV.png'" alt=""></a>
        </div>
        <div class="tw-w-full tw-p-1">
            <div class="tw-flex tw-justify-between tw-items-center">
                <div class="">
                <a (click)="openModalAddProduct(item)"><p class="tw-text-xs md:tw-text-xs tw-font-bold tw-line-clamp-3 tw-mb-1 tw-text-black hover:tw-text-blue-950">{{item.name}}</p></a>
                <p class="tw-text-xs md:tw-text-xs tw-my-1"><strong>Código</strong>&nbsp;{{item.sku}}</p>
                </div>
                <div class="">
                <div class="tw-flex tw-items-center">
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>  
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-items-center">
        <div class="tw-col-span-1 tw-pr-2">
        <a [href]="'https://api.whatsapp.com/send?text='+firestoreService.empresaData?.messageStock+' '+item.name+' '+'&phone=' + firestoreService.empresaData?.telefono" target="_blank"><button class="tw-text-white tw-bg-green-600 tw-my-3 tw-py-2 tw-rounded-lg tw-m-1 tw-w-full tw-border-green-600"><i class="fab fa-whatsapp"></i>&nbsp;&nbsp;Asesor online</button></a>
        </div>
        <div class="tw-col-span-1 tw-pr-3">
        <button class="tw-text-blue-950 tw-bg-white tw-my-3 tw-py-2 tw-rounded-lg tw-m-1 tw-w-full tw-border tw-border-blue-950" [routerLink]="['/main', item.id, item.name]"><i class="fas fa-eye"></i>&nbsp;&nbsp;Ver detalle</button>
        </div>
    </div>
    <div class="tw-flex tw-border-t tw-border-gray-400">
        <button class="tw-text-white tw-bg-blue-950 tw-my-3 tw-py-2 tw-w-full tw-rounded-lg tw-m-1" (click)="openModalDetalleProduct(item)"><i class="fas fa-info-circle"></i>&nbsp;&nbsp;Componentes</button>
    </div>
</div>