<ion-header>
    <ion-toolbar>
      <ion-title class="tw-text-sm">{{ item.description }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="modalController.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content>
    <iframe [src]="item.urlFichaTecnica | safe" width="100%" height="100%"></iframe>
  </ion-content>