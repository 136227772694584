import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFilter'
})
export class CustomFilterPipe implements PipeTransform {
  transform(items: any[], searchTerm?: string, maxPrice?: number, brands?: string[]): any[] {
    if (!items || ((!searchTerm || searchTerm === '') && (!maxPrice || maxPrice === 0) && (!brands || brands.length === 0))) {
      return items;
    }
    return items.filter(item => {
      const nameMatch = !searchTerm || (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()));
      const priceMatch = !maxPrice || (item.prices && item.prices.discount && item.prices.discount <= maxPrice);
      const brandMatch = !brands || brands.length === 0 || (item.marcaN && brands.some(brand => item.marcaN.includes(brand['name'])));
      return nameMatch && priceMatch && brandMatch;
    });
  }
}