import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { IonSearchbar, ModalController } from '@ionic/angular';
import { SearchFilterContentComponent } from '../search-filter-content/search-filter-content.component';
import { KeyboardService } from 'src/app/services/keyboard.service';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})
export class SearchFilterComponent {
  @ViewChild('ionSearchbar') ionSearchbar!: IonSearchbar;
  @ViewChild('tags') tags!: SearchFilterContentComponent;
  @ViewChild('brands') brands!: SearchFilterContentComponent;
  @Output() searchEvent = new EventEmitter<any>();
  _listMarcas;
  constructor(
    public apiService: ApiService,
    public keyboardService: KeyboardService,
    public modalController: ModalController
  ){

  }
  ngOnInit(){
    this.listMarcas();
  }
  listMarcas(){
    this.apiService.listMarcas().subscribe((resp)=>{
      this._listMarcas=resp;
    })
  }
  search() {
    // let selectedTags = this.tags.content.filter(tag => tag.selected).map(tag => tag.id).join(',');
    // if (selectedTags.endsWith(',')) {
    //   selectedTags = selectedTags.slice(0, -1);
    // }
    let selectedBrands = this.brands.content.filter(brand => brand.selected).map(brand => brand.id).join(',');
    if (selectedBrands.endsWith(',')) {
      selectedBrands = selectedBrands.slice(0, -1);
    }
    this.searchEvent.emit({
      search: this.ionSearchbar.value,
      // tags: selectedTags,
      brands: selectedBrands,
    });
    this.modalController.dismiss();
  }
  searchTableros() {
    this.searchEvent.emit({
      isBom: true,
    });
  }
}
