import { Component } from '@angular/core';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  navbarOpenSubject;
  constructor(
    public navbarService: NavbarService
  ){
    this.navbarService.navbarOpenSubject$.subscribe((resp)=>{
      this.navbarOpenSubject=resp;
    })
  }
  
}
