import { Injectable } from '@angular/core';
import { SweetalertsService } from './sweetalerts.service';
import { UtilsService } from './utils.service';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  currentCartSubject$ = new BehaviorSubject(null);
  currentBuildSubject$ = new BehaviorSubject([]);
  currentOrderAmountsSubject$ = new BehaviorSubject(null);
  currentBuildAmountsSubject$ = new BehaviorSubject(null);
  currentOrderAmounts;
  currentBuildAmounts;
  currentCart;
  currentBuild;
  constructor(
    public sweetalertsService: SweetalertsService,
    public utilsService: UtilsService,
    public modalController: ModalController,
    public router: Router
  ) { 
    if(this.utilsService.getLocalStorage("currentCart")){
      this.currentCartSubject$.next(this.utilsService.getLocalStorage("currentCart"));
      this.currentOrderAmountsSubject$.next(this.currentOrderAmounts);
    }
    if(this.utilsService.getLocalStorage("currentBuild")){
      this.currentBuildSubject$.next(this.utilsService.getLocalStorage("currentBuild"));
      this.currentBuildAmountsSubject$.next(this.currentBuildAmounts);
    }
    this.currentCartSubject$.subscribe((resp)=>{
      this.currentCart=resp;
      this.getOrderAmounts();
    });
    this.currentBuildSubject$.subscribe((resp)=>{
      this.currentBuild=resp;
      console.info("currentBuildSubject: ",this.currentBuild);
      this.getBuildAmounts();
    });
  }
  addProductToCart(item,cantidad){
    item.cantidad=cantidad;
    this.sweetalertsService.loading("Espere por favor");
    this.addProductToCartMethod(item);
  }
  private addProductToCartMethod(item) {
    let tempCart = this.utilsService.getLocalStorage("currentCart") || [];
    let existingProductIndex = tempCart.findIndex(cartItem => cartItem.id === item.id);
    if (existingProductIndex != -1) {
      tempCart[existingProductIndex].cantidad += item.cantidad;
    } else {
      tempCart.push(item);
    }
    this.utilsService.setLocalStorage("currentCart", tempCart);
    this.currentCartSubject$.next(tempCart);
    this.sweetalertsService.generalSuccess("Producto agregado");
    this.modalController.getTop().then(modal => { modal ? this.modalController.dismiss({}) : {}; });
  }
  addProductToBuild(item,cantidad){
    item.cantidad=cantidad;
    this.sweetalertsService.loading("Espere por favor");
    this.addProductToBuildMethod(item);
  }
  private addProductToBuildMethod(item){
    let tempCart=this.utilsService.getLocalStorage("currentBuild")?this.utilsService.getLocalStorage("currentBuild"):[];
    tempCart.push(item);
    this.utilsService.setLocalStorage("currentBuild",tempCart);
    this.currentBuildSubject$.next(this.utilsService.getLocalStorage("currentBuild"));
    this.sweetalertsService.generalSuccess("Producto agregado");
    this.modalController.getTop().then(modal => {modal?this.modalController.dismiss({}):{};});
  }
  updateCart(currentCart){
    this.utilsService.setLocalStorage("currentCart",currentCart);
    this.currentCartSubject$.next(this.utilsService.getLocalStorage("currentCart"));
  }
  updateBuild(currentBuild){
    this.utilsService.setLocalStorage("currentBuild",currentBuild);
    this.currentBuildSubject$.next(this.utilsService.getLocalStorage("currentBuild"));
  }
  clearCart(){
    this.sweetalertsService.questionAlert("¿Desea vaciar el carrito?","Si","No","Hecho").then((resp)=>{
      if(resp){
        this.utilsService.setLocalStorage("currentCart",[]);
        this.currentCartSubject$.next(this.utilsService.getLocalStorage("currentCart"));
      }
    })
  }
  clearBuild(){
    this.sweetalertsService.questionAlert("¿Desea vaciar la cotización?","Si","No","Hecho").then((resp)=>{
      if(resp){
        this.utilsService.setLocalStorage("currentBuild",[]);
        this.currentBuildSubject$.next(this.utilsService.getLocalStorage("currentBuild"));
      }
    })
  }
  clearCartOnly(){
    this.utilsService.setLocalStorage("currentCart",[]);
    this.currentCartSubject$.next(this.utilsService.getLocalStorage("currentCart"));
  }
  clearBuildOnly(){
    this.utilsService.setLocalStorage("currentBuild",[]);
    this.currentBuildSubject$.next(this.utilsService.getLocalStorage("currentBuild"));
  }
  removeProductFromCart(i){
    let temp=this.utilsService.getLocalStorage("currentCart");
    temp.splice(i,1);
    if(temp.length==0){
      this.utilsService.removeLocalStorage('currentCart');
      this.currentCartSubject$.next(null);
    }else{
      this.utilsService.setLocalStorage('currentCart',temp);
      this.currentCartSubject$.next(this.utilsService.getLocalStorage("currentCart"));
    }
  }
  removeProductFromBuild(i){
    let temp=this.utilsService.getLocalStorage("currentBuild");
    temp.splice(i,1);
    if(temp.length==0){
      this.utilsService.removeLocalStorage('currentBuild');
      this.currentBuildSubject$.next(null);
    }else{
      this.utilsService.setLocalStorage('currentBuild',temp);
      this.currentBuildSubject$.next(this.utilsService.getLocalStorage("currentBuild"));
    }
  }
  getOrderAmounts(){
      this.currentOrderAmounts={
        // productCost:this.getCartSubTotal(),
        // descuento:this.getDescuentos(),
        subtotal:this.getCartSubTotal(),
        impuestos:this.getCartSubTotal()*0.18,
        // total:this.getCartSubTotal()+this.getCartSubTotal()*0.18,
        total:this.getCartSubTotal(),
      }
      this.currentOrderAmountsSubject$.next(this.currentOrderAmounts);
  }
  getBuildAmounts(){
    this.currentBuildAmounts={
      // productCost:this.getCartSubTotal(),
      // descuento:this.getDescuentos(),
      subtotal:this.getBuildSubTotal(),
      impuestos:this.getBuildSubTotal()*0.18,
      total:this.getBuildSubTotal()+this.getBuildSubTotal()*0.18,
    }
    this.currentBuildAmountsSubject$.next(this.currentBuildAmounts);
  }
  makeBuildToCart(){
    this.updateCart(this.currentBuild);
    this.clearBuildOnly();
    this.router.navigate(['/main/checkout']);
  }
  private getCartSubTotal() {
    return (this.currentCart ?? []).reduce((accumulator, object) => {
      return accumulator + (object.prices?.discount || 0) * object.cantidad;
    }, 0);
  }
  private getBuildSubTotal() {
    return (this.currentBuild ?? []).reduce((accumulator, object) => {
      return accumulator + (object.prices?.discount || 0) * object.cantidad;
    }, 0);
  }
}
