import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedGuard } from './guards/logged.guard';
import { AuthGuard } from './guards/auth.guard';

let routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'main',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    redirectTo: 'main/home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginModule)
  },
  // {
  //   path: 'reset-pass',
  //   canActivate: [LoggedGuard],
  //   loadChildren: () => import('./pages/reset-pass/reset-pass.module').then( m => m.ResetPassModule)
  // },
  {
    path: 'signin',
    canActivate: [LoggedGuard],
    loadChildren: () => import('./pages/signin/signin.module').then( m => m.SigninModule)
  },
  {
    path: 'main',
    // canActivate: [AuthGuard],
    loadChildren: () => import('./pages/main/main.module').then( m => m.MainModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
