import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-modal-add-product',
  templateUrl: './modal-add-product.component.html',
})
export class ModalAddProductComponent {
  selectedProduct;
  constructor(
    private navParams: NavParams,
    public cartService: CartService,
    public modalController: ModalController,
  ){
  }
  ngOnInit(){
    this.selectedProduct = this.navParams.get('selectedProduct');
  }
}
