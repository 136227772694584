import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-direcciones-component',
  templateUrl: './direcciones-component.component.html',
})
export class DireccionesComponentComponent {
  @Input() showSelectedAddress;
  @Output() selectedAddressEvent = new EventEmitter<any>();
  selectedAddress;
  step=1;
  form;
  currentUser;
  department_list=[];
  province_list=[];
  district_list=[];
  direcciones_list;
  getDirecciones$;
  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    public apiService: ApiService,
    public firestoreService: FirestoreService,
    public sweetalertsService: SweetalertsService
  ){
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
      if(this.form){
        this.form.patchValue(this.currentUser);
      }
    })
    this.form = this.formBuilder.group({
      id:[''],
      departamento: ['', Validators.required],
      departamentoText: [''],
      provincia: ['', Validators.required],
      provinciaText: [''],
      distrito: ['', Validators.required],
      distritoText: [''],
      address: ['', Validators.required],
      active: [''],
    });
  }
  ngOnInit(){
    this.getDepartament();
    this.getDirecciones();
  }
  ionViewWillEnter(){
    this.step=1;
  }
  addAddress(){
    this.step=2;
  }
  update(item){
    this.sweetalertsService.questionAlert("¿Eliminar dirección?","Si","No","Procesando").then((resp)=>{
      if(resp){
        this.firestoreService.update(`empresas/SUBACOM/users/${this.currentUser.email}/direcciones/`,{active:false},item.id).subscribe((resp)=>{
          this.getDirecciones();
          this.step=1;
          this.sweetalertsService.generalSuccess("Direccion eliminada");
        },(error)=>{
          this.sweetalertsService.generalError("No se pudo eliminar la dirección");
        });
      }
    })
  }
  save(){
    this.sweetalertsService.questionAlert("¿Los datos son correctos?","Si","No","Procesando").then((resp)=>{
      if(resp){
        let tempId=new Date().getTime();
        this.form.controls['id'].setValue(tempId);
        this.form.controls['active'].setValue(true);
        this.firestoreService.set(`empresas/SUBACOM/users/${this.currentUser.email}/direcciones/`,this.form.getRawValue(),tempId).subscribe((resp)=>{
          this.getDirecciones();
          this.step=1;
          this.sweetalertsService.generalSuccess("Direccion agregada");
        },(error)=>{
          this.sweetalertsService.generalError("No se pudo agregar la dirección");
        });
      }
    });
  }
  selectedAddressEventEmit(item){
    this.selectedAddressEvent.emit(item);
  }
  getDirecciones(){
    this.getDirecciones$=this.firestoreService.getDirecciones().subscribe((resp)=>{
      console.info("getDirecciones: ",resp);
      this.direcciones_list=resp;
      this.getDirecciones$.unsubscribe();
      if(this.direcciones_list.length>0){
        this.selectedAddress=this.direcciones_list[0];
        this.selectedAddressEventEmit(this.selectedAddress);
        console.info("selectedAddress: ",this.selectedAddress);
      }
    });
  }
  //////////////////////////////////////////
  getDepartament(){
    this.apiService.getDepartament().subscribe((resp)=>{
      this.department_list=resp['data'];
    })
  }
  getProvince(departamentID){
    this.apiService.getProvince(departamentID).subscribe((resp)=>{
      this.province_list=resp['data'];
    })
  }
  getDistrict(provinceID){
    this.apiService.getDistrict(provinceID).subscribe((resp)=>{
      this.district_list=resp['data'];
    })
  }
  ///////////////////////////////////////////
  onSelectDepartamento(event){
    console.info("event: ", event.target.value);
    const foundItem = this.department_list.find(item => item.id === event.target.value); // Encuentra el elemento en el array
    this.form.controls['departamentoText'].setValue(foundItem.name); // Establece el valor en el control
    this.getProvince(event.target.value)
  }
  onSelectProvincia(event){
    console.info("event: ",event.target.value);
    const foundItem = this.province_list.find(item => item.id === event.target.value); // Encuentra el elemento en el array
    this.form.controls['provinciaText'].setValue(foundItem.name); // Establece el valor en el control
    this.getDistrict(event.target.value)
  }
  onSelectDistrito(event){
    console.info("event: ",event.target.value);
    const foundItem = this.district_list.find(item => item.id === event.target.value); // Encuentra el elemento en el array
    this.form.controls['distritoText'].setValue(foundItem.name); // Establece el valor en el control
  }
}
