<div class="tw-grid tw-grid-cols-4 tw-px-2 tw-py-2">
    <div class="tw-col-span-5 md:tw-col-span-1 tw-flex tw-justify-center">
        <ion-searchbar #ionSearchbar id="ionSearchbar" class="tw-border tw-border-gray-600 tw-rounded-full customSearch tw-bg-white" placeholder="Buscar"></ion-searchbar>
    </div>
    <!-- <div class="tw-col-span-1 tw-flex tw-justify-center">
        <button class="tw-bg-blue-950 tw-text-white tw-w-3/4 tw-font-bold tw-rounded-full tw-h-12 tw-px-3 tw-mx-1 tw-border tw-border-blue-950 tw-flex tw-justify-around tw-items-center" type="button"><span class="tw-w-5/6">Tags todos</span><span uk-drop-parent-icon></span></button>
        <div uk-drop="mode: click">
            <app-search-filter-content (searchEvent)="search();keyboardService.escPress()" id="tags" #tags [content]="_listMarcas" [title]="'Tags'"></app-search-filter-content>
        </div>
    </div> -->
    <div class="tw-col-span-5 md:tw-col-span-1 tw-flex tw-justify-center">
        <button class="tw-bg-blue-950 tw-text-white tw-w-3/4 tw-font-bold tw-rounded-full tw-h-12 tw-px-3 tw-mx-1 tw-border tw-border-blue-950 tw-flex tw-justify-around tw-items-center" type="button"><span class="tw-w-5/6">Marcas todos</span><span uk-drop-parent-icon></span></button>
        <div uk-drop="mode: click" style="top: 65px;">
            <app-search-filter-content (searchEvent)="search();keyboardService.escPress()" id="brands" #brands [content]="_listMarcas" [title]="'Marcas'"></app-search-filter-content>
        </div>
    </div>
    <!-- <div class="tw-col-span-5 md:tw-col-span-1 tw-flex tw-justify-center">
        <button class="tw-bg-blue-950 tw-text-white tw-w-3/4 tw-font-bold tw-rounded-full tw-h-12 tw-px-3 tw-mx-1 tw-border tw-border-blue-950 tw-flex tw-justify-around tw-items-center" type="button" (click)="searchTableros()"><span class="tw-w-5/6">Tableros</span></button>
    </div> -->
    <div class="tw-col-span-5 md:tw-col-span-1 tw-flex tw-justify-center">
        <button class="tw-bg-blue-950 tw-text-white tw-w-3/4 tw-font-bold tw-rounded-full tw-h-12 tw-px-3 tw-mx-1 tw-border tw-border-blue-950 tw-flex tw-justify-around tw-items-center" (click)="search()"><i class="fas fa-search"></i></button>
    </div>
</div>