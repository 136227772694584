import { Component } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';

@Component({
  selector: 'app-modal-detalle-product',
  templateUrl: './modal-detalle-product.component.html',
  styleUrls: ['./modal-detalle-product.component.scss']
})
export class ModalDetalleProductComponent {
    item;
    currentCart;
    tablero;
    constructor(
      public cartService: CartService,
      private navParams: NavParams,
      public modalController: ModalController,
      public apiService: ApiService,
      public sweetalertsService: SweetalertsService
    ){
      this.cartService.currentCartSubject$.subscribe((resp)=>{
        this.currentCart=resp;
        // console.warn("currentCart: ",this.currentCart);
      });
    }
    ngOnInit(){
      this.item = this.navParams.get('item');
      this.infoProduccion();
    }
    infoProduccion(){
      this.sweetalertsService.loading();
      this.apiService.infoProduccion(this.item.id).subscribe((resp)=>{
        this.tablero=resp['rows'];
        this.sweetalertsService.dissmissSwal();
      })
    }
}
