import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-camera-preview',
  templateUrl: './camera-preview.component.html',
})
export class CameraPreviewComponent {
  @Output() preImg = new EventEmitter<any>();
  previewImage;
  file: File;
  @Input() titulo;
  constructor() { }
  async photo() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.DataUrl,
      saveToGallery: true,
      source: CameraSource.Camera
    });
    this.previewImage = image.dataUrl;
    this.preImg.emit(this.previewImage);
    this.dataURLtoFile(image.dataUrl).then((data:any)=>{
      this.file=data;
    })
  }
  storage() {
    const inputElement = document.querySelector('input[type="file"]') as HTMLInputElement;
    inputElement.click();
  }
  handleImageUpload(event: Event) {
    const target = event.target as HTMLInputElement;
    this.file = (target.files as FileList)[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.previewImage = reader.result as string;
      this.preImg.emit(this.previewImage);
    };
  }
  removeImage() {
    this.previewImage = null;
    this.preImg.emit(this.previewImage);
  }
  dataURLtoFile(dataUrlParam){
    return new Promise((resolve)=>{
      fetch(dataUrlParam)
      .then(response => response.blob())
      .then(blob => {
          const file = new File([blob], uuidv4()+'.jpg', {type: blob.type})
          resolve(file);
      })
    })
  }
}
