import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }
  setLocalStorage(name, value){localStorage.setItem(name,JSON.stringify(value));}
  removeLocalStorage(name){localStorage.removeItem(name);}
  getLocalStorage(name){
    if(localStorage.getItem(name)){
      return JSON.parse(localStorage.getItem(name));
    }else{
      return undefined;
    }
  }
  clearLocalStorage(){
    localStorage.clear();
  }
}
