import { Component, ViewChild } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { SearchFilterV2Component } from 'src/app/components/search-filter-v2/search-filter-v2.component';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-modal-search-add-product',
  templateUrl: './modal-search-add-product.component.html',
  styleUrls: ['./modal-search-add-product.component.scss'],
})
export class ModalSearchAddProductComponent {
  @ViewChild("searchFilterv2search2") searchFilterv2search2: SearchFilterV2Component;
  searchTerm;
  params;
  responsiveSearch=true;
  searchList;
  tag;
  minPrice=1;
  maxPrice=20000;
  page = 1;
  limit = 8;
  totalItems=1;
  marcaID;
  brandsFilter=[];
  constructor(
    public modalController: ModalController,
    public apiService: ApiService,
    public navParams: NavParams,
    public cartService: CartService
  ){

  }
  ngOnInit(){
    this.tag = this.navParams.get('tag');
    console.info("this.tag: ",this.tag);
    // this.listProductos(this.tag);t
    this.listFilter(this.tag);
    this.getParams();
  }
  search(event?,page?){

  }
  public qMinus(item){
    if(+item.cantidad>1){
      item.cantidad = +item.cantidad-1;
    }
  }
  public qPlus(item){
    if(+item.cantidad<item.stock.qty){
      item.cantidad = +item.cantidad+1;
    }
  }
  getParams(){
    let limit = this.limit? `&limit=${this.limit}` : '';
    let page = this.page? `&page=${this.page-1}` : '';
    let marcas = this.marcaID? `&marcaID=${this.marcaID}` : '';
    let price = `&price=${this.minPrice},${this.maxPrice}`;
    let tag = `&tags=${this.tag}`;
    this.listProductos(`${page}${limit}${tag}${marcas}${price}`);
  }
  listProductos(params){
    this.apiService.listProductos(params).subscribe(resp => {
      console.info("listProductos: ",resp);
      this.totalItems=resp['count'];
      this.searchList=resp['data']['result'];
    });
  }
  listFilter(tag){
    this.apiService.listFilter(`tags=${tag}`).subscribe(resp => {
      console.info("listFilter: ",resp);
      this.searchFilterv2search2.marcas=resp['marcas'];
    });
  }
  addProductToBuild(item){
    this.cartService.addProductToBuild(item,item.cantidad);
  }
  onPriceChange(value) {
    this.minPrice=Number(value['min']);
    this.maxPrice=Number(value['max']);
    console.info("onPriceChange; ",this.minPrice,this.maxPrice);
    this.getParams();
  }
  onBrandsChange(value) {
    this.brandsFilter = value && value.length > 0 ? [...value] : [];
    this.marcaID = this.brandsFilter.map(brand => brand.id).join(',');
    console.info("brandsFilter: ", this.marcaID);
    this.getParams();
  }
  onTagsChange(value: any) {
    // if (!value || value.length === 0) {
    //   this.router.navigate(['main/search']);
    // } else {
    //   this.router.navigate(['main/search'], { queryParams: { search: value[0] } });
    // }
  }
}
