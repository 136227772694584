<div class="tw-bg-stone-900 tw-grid tw-grid-cols-2 tw-px-2 tw-py-2 md:tw-px-20 md:tw-py-8">
    <div class="tw-col-span-2 md:tw-col-span-1 tw-px-6">
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
            <div class="tw-col-span-1 tw-py-4">
                <div class="tw-border-b-2 tw-border-amber-500 tw-pb-1 tw-inline-block tw-w-auto tw-mb-5">
                    <span class="tw-text-white tw-text-xl">Garantías</span>
                </div>
                <p class="tw-text-white tw-mb-1">Términos y condiciones</p>
                <p class="tw-text-white tw-mb-1">Términos de garantía</p>
                <p class="tw-text-white tw-mb-1">Política de privacidad</p>
                <p class="tw-text-white tw-mb-1">Envíos a provincia</p>
                <p class="tw-text-white tw-mb-1">Reclamos</p>
                <p class="tw-text-white tw-mb-1">Modalidades de Pago</p>
            </div>
            <div class="tw-col-span-1 tw-py-4">
                <div class="tw-border-b-2 tw-border-amber-500 tw-pb-1 tw-inline-block tw-w-auto tw-mb-5">
                    <span class="tw-text-white tw-text-xl">Acerca de nosotros</span>
                </div>
                <p class="tw-text-white tw-mb-1">Soporte técnico</p>
                <p class="tw-text-white tw-mb-1">Trabaja con nosotros</p>
                <p class="tw-text-white tw-mb-1">Formulario de contacto</p>
                <p class="tw-text-white tw-mb-1">Sobre Nosotros</p>
                <div class="tw-flex tw-justify-start">
                    <img class="tw-w-12 tw-h-12" src="assets/IMAGENES/REDES/Group 9.png" alt="">
                    <img class="tw-w-12 tw-h-12 tw-mx-6" src="assets/IMAGENES/REDES/tiktok.png" alt="">
                    <img class="tw-w-12 tw-h-12" src="assets/IMAGENES/REDES/youtube.png" alt="">
                </div>
            </div>
            <div class="tw-col-span-1 tw-py-4">
                <div class="tw-border-b-2 tw-border-amber-500 tw-pb-1 tw-inline-block tw-w-auto tw-mb-5">
                    <span class="tw-text-white tw-text-xl">Contáctanos</span>
                </div>
                <p class="tw-text-white tw-mb-1">tiendasubacom@gmail.com</p>
                <p class="tw-text-white tw-mb-1">tiendasubacom@subacom.com.pe</p>
                <p class="tw-text-white tw-mb-1">subacomgarantias@gmail.com</p>
                <!-- <p class="tw-text-white tw-mb-1">Chatea con Nosotros</p> -->
            </div>
            <div class="tw-col-span-1 tw-py-4">
                <div class="tw-border-b-2 tw-border-amber-500 tw-pb-1 tw-inline-block tw-w-auto tw-mb-5">
                    <span class="tw-text-white tw-text-xl">Ubicación</span>
                </div>
                <p class="tw-text-white tw-mb-1">Calle Octavio Muñoz Najar 223 C. Comercial CompuArequipa, Interior 1er piso (Nro. 103, 107, 111, 115, 121) 2do Piso (Nro. 202 y 226)</p>
            </div>
        </div>
    </div>
    <div class="tw-col-span-2 md:tw-col-span-1">
        <div>
            <iframe class="tw-rounded-md tw-w-full tw-h-[calc(250px)] md:tw-h-[calc(450px)]" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3827.4622971610024!2d-71.53210992414712!3d-16.401331184327287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91424a55c9a6be6d%3A0x27fb8c11e4ddb174!2sOctavio%20Mu%C3%B1oz%20Najar%20223%2C%20Arequipa%2004001!5e0!3m2!1ses-419!2spe!4v1696869507007!5m2!1ses-419!2spe" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
</div>
<div class="tw-bg-stone-800 tw-w-full tw-text-center tw-py-2 tw-border-t tw-border-gray-600">
    <p class="tw-text-xs tw-mb-0.5 tw-font-bold tw-text-gray-200">Copyright @ 2023 Subacom - by <a href="https://simplexlatam.com/" target="_blank">Simplex-ERP.</a></p>
</div>