import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptors/httpConfig.interceptor';
import { IonicModule } from '@ionic/angular';
import { ModalFichaComponent } from './pages/modal-ficha/modal-ficha.component';
import { ModalAddProductComponent } from './pages/modal-add-product/modal-add-product.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment.prod';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HTTP } from '@awesome-cordova-plugins/http';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalDetalleProductComponent } from './pages/main/modal-detalle-product/modal-detalle-product.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { ModalFilterComponent } from './pages/modal-filter/modal-filter.component';
import { ComponentsModule } from './components/components.module';
import { ModalSearchAddProductComponent } from './pages/modal-search-add-product/modal-search-add-product.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { ModalCotizacionProcessComponent } from './pages/modal-cotizacion-process/modal-cotizacion-process.component';
import { NgPipesModule } from 'ngx-pipes';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [
    AppComponent,
    ModalFichaComponent,
    ModalAddProductComponent,
    ModalDetalleProductComponent,
    ModalFilterComponent,
    ModalSearchAddProductComponent,
    ModalCotizacionProcessComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    IonicModule.forRoot(),
    IonicModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    BrowserAnimationsModule,
    NgxGalleryModule,
    NgPipesModule,
    ComponentsModule,
    NgxPaginationModule,
    PipesModule
  ],
  providers: [
    // HTTP,
    // File,
    // FilePath,
    AngularFirestore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
