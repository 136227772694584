import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SweetalertsService } from './sweetalerts.service';
import { UtilsService } from './utils.service';
import { CartService } from './cart.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private currentUser;
  currentUserSubject$ = new BehaviorSubject(null);
  constructor(
    public http: HttpClient,
    public utilsService: UtilsService,
    private router: Router,
    public sweetalertsService: SweetalertsService,
    public cartService: CartService
  ) {
    if(this.getCurrentUser()){
      this.currentUserSubject$.next(this.getCurrentUser());
    }
  }
  getCurrentUser(){
    return this.utilsService.getLocalStorage("currentUser");
  }
  public setCurrentUser(currentUser){
    this.currentUser=currentUser;
    this.utilsService.setLocalStorage("currentUser",currentUser);
    this.currentUserSubject$.next(currentUser);
  }
  public removeCurrentUser(){
    this._removeCurrentUser();
    this.router.navigate(['/login']);
    this.cartService.clearCartOnly();
    this.sweetalertsService.generalSuccess("Hasta luego.");
    // this.menuController.close();
  }
  private _removeCurrentUser(){
    this.currentUser=undefined;
    this.utilsService.removeLocalStorage("currentUser");
    this.currentUserSubject$.next(null);
  }
  /*
  * extra methods
  */
  getRol(){
    // return this.currentUser.user.position;
    return this.currentUser.type;
  }
}

