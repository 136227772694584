<nav [ngClass]="navbarOpenSubject?'tw-w-20':'tw-hidden'" class="menu tw-h-[calc(100vh)] tw-pt-12 md:tw-pt-24 tw-absolute tw-bg-blue-950 tw-z-30">
	<ul class="level1">
		<li id="l1">
            <div class="tw-px-5 tw-py-8 tw-text-center triangle">
                <i class="fas fa-search tw-text-2xl tw-text-white"></i>
            </div>
			<ul class="level2 tw-top-[calc(95px)]">
				<li class="tw-ml-[calc(9px)] md:tw-ml-1.5 tw-bg-gray-500 tw-w-[calc(300px)] tw-h-[calc(100vh-79px)]">
                    <div routerLink="/main/home">
                        <a class="tw-text-white tw-px-6 tw-py-4 tw-flex tw-items-center"><i class="fas fa-search tw-text-white tw-pr-3"></i><span class="w-text-white">Buscar productos</span></a>
                    </div>
                    <div routerLink="/main/checkout">
                        <a class="tw-text-white tw-px-6 tw-py-4 tw-flex tw-items-center"><i class="fas fa-shopping-cart tw-text-white tw-pr-3"></i><span class="w-text-white">Carrito</span></a>
                    </div>
                    <div routerLink="/main/cotizacion">
                        <a class="tw-text-white tw-px-6 tw-py-4 tw-flex tw-items-center"><i class="fas fa-pen tw-text-white tw-pr-3"></i><span class="w-text-white">Cotizacion manual</span></a>
                    </div>
                </li>
			</ul>
		</li>
        <li id="l2">
            <div class="tw-px-5 tw-py-8 tw-text-center triangle">
                <i class="fas fa-folder tw-text-2xl tw-text-white"></i>
            </div>
			<ul class="level2 tw-top-[calc(95px)]">
				<li class="tw-ml-[calc(9px)] md:tw-ml-1.5 tw-bg-gray-500 tw-w-[calc(300px)] tw-h-[calc(100vh-79px)]">
                    <div routerLink="/main/mis-pedidos">
                        <a class="tw-text-white tw-px-6 tw-py-4 tw-flex tw-items-center"><i class="fas fa-file-signature tw-text-white tw-pr-3"></i><span class="w-text-white">Historial de pedidos</span></a>
                    </div>
                    <div routerLink="/main/mis-cotizaciones">
                        <a class="tw-text-white tw-px-6 tw-py-4 tw-flex tw-items-center"><i class="fas fa-history tw-text-white tw-pr-3"></i><span class="w-text-white">Historial de cotizaciones</span></a>
                    </div>
                </li>
			</ul>
		</li>
	</ul>
</nav>