<!-- <ion-row *ngIf="!previewImage">
  <ion-col><ion-button shape="round" fill="outline" color="success" expand="block" (click)="photo()">Foto</ion-button></ion-col>
  <ion-col><ion-button shape="round" fill="outline" color="primary" expand="block" (click)="storage()">Galeria</ion-button></ion-col>
</ion-row> -->
<ion-row *ngIf="!previewImage">
  <ion-col>
    <div class="tw-border tw-border-gray-500 tw-rounded-3xl tw-py-20 tw-text-center tw-mx-10" (click)="storage()">
      <span class="tw-text-xl">{{titulo}}</span>
    </div>
  </ion-col>
</ion-row>
<div class="tw-flex tw-justify-center">
  <div *ngIf="previewImage" class="tw-relative tw-rounded-full tw-w-40 tw-h-40 tw-bg-gray-200 tw-flex tw-justify-center tw-items-center tw-mb-5">
    <img [src]="previewImage" class="tw-rounded-md tw-w-40 tw-h-40 tw-object-cover" />
    <button (click)="removeImage()" class="tw-absolute tw-top-0 tw-right-0 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gray-300 tw-w-8 tw-h-8"><i class="tw-text-white fas fa-times"></i></button>
  </div>
</div>
<input *ngIf="!previewImage" type="file" accept="image/*" (change)="handleImageUpload($event)" class="tw-hidden"/>

