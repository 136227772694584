import { Component } from '@angular/core';
import { MenuController, ModalController } from '@ionic/angular';
import { NavbarService } from 'src/app/services/navbar.service';
import { UserService } from 'src/app/services/user.service';
import { CartComponent } from '../cart/cart.component';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import { SearchFilterMobileComponent } from '../search-filter-mobile/search-filter-mobile.component';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { CategoryMenuComponent } from '../category-menu/category-menu.component';
import { ApiService } from 'src/app/services/api.service';
import { SweetalertsService } from 'src/app/services/sweetalerts.service';
declare var UIkit: any;

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent {
  currentCart;
  currentUser;
  searchForm;
  constructor(
    public navbarService: NavbarService,
    public userService: UserService,
    public modalController: ModalController,
    public menuController: MenuController,
    public cartService: CartService,
    public formBuilder: FormBuilder,
    public router: Router,
    public apiService: ApiService,
    public sweetalertsService: SweetalertsService
  ){
    this.cartService.currentCartSubject$.subscribe((resp)=>{
      this.currentCart=resp;
      // console.warn("currentCart: ",this.currentCart);
    });
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
      // console.warn("currentUser: ",this.currentUser);
    })
    this.searchForm = this.formBuilder.group({
      search: ['', [Validators.required, Validators.minLength(3)]]
    });
    this.navbarService.searchBarSubject$.subscribe((resp)=>{
      console.info("searchSubject: ",resp);
      if(resp['clear']){
        this.searchForm.controls['search'].setValue('');
      }
    })
  }
  ngOnInit(){
    this._initUikitEvents();
  }
  _initUikitEvents(){
    const accordion = UIkit.accordion('#categoriasMenu');
    UIkit.util.on('#categoriasMenu', 'show', (event) => {
      document.getElementById('velo').classList.remove('tw-hidden');
    });
    UIkit.util.on('#categoriasMenu', 'hide', (event) => {
      document.getElementById('velo').classList.add('tw-hidden');
    });
  }
  async openModalCart(){
    const modal = await this.modalController.create({
      component: CartComponent,
      cssClass:'cartComponentStyle',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  async openModalCategoryMenu(){
    const modal = await this.modalController.create({
      component: CategoryMenuComponent,
      // cssClass:'transparentModal',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  async openModalSearch(){
    const modal = await this.modalController.create({
      component: SearchFilterMobileComponent,
      // cssClass:'transparentModal',
    });
    modal.onDidDismiss().then((resp) => {
      if(resp['data']){
        if(resp['data'].success){

        }
      }
    });
    return await modal.present();
  }
  reload() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    this.router.navigate(['/main/home']));
 }
 search(){
  console.info("asdasdsadsa");
  if (this.searchForm.valid) {
    this.router.navigate(['/main/search'], { queryParams: { search: this.searchForm.controls['search'].value}});
    this.searchForm.reset();
  } else {
    // this.sweetalertsService.generalWarning("Introduzca tres caracteres como mínimo");
  }
  // this.apiService.listProductos(`name=${this.searchForm.controls['search'].value}`).subscribe(resp => {
  //   console.info("listProductos: ",resp);
  //   this.totalItems=resp['count'];
  //   this.items=resp['result'];
  // });
  }
  closeCategoryMenu(){
    // const accordion = UIkit.accordion('#categoriasMenu');
    console.info("closeCategoryMenu");
    UIkit.dropdown('#categoriasMenu').hide(1000);
  }
}
