<ion-header>
    <ion-toolbar>
        <ion-title class="tw-mt-1">Mi carrito</ion-title>
        <ion-buttons class="tw-block md:tw-hidden" slot="end">
            <ion-button (click)="modalController.dismiss()">
                <ion-icon slot="icon-only" name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="tw-h-96 tw-overflow-auto">
    <div>
        <ng-container *ngIf="!currentCart || currentCart.length == 0; else cartNotEmpty">
            <div class="tw-flex tw-justify-center tw-items-center tw-h-96">
                <div class="tw-text-center">
                    <ion-icon name="cart-outline" class="tw-text-6xl tw-text-orange-600"></ion-icon>
                    <p>Carrito vacío</p>
                </div>
            </div>
        </ng-container>
        <ng-template #cartNotEmpty>
            <!-- mobile -->
            <div class="tw-grid md:tw-hidden tw-grid-cols-3 tw-py-3 tw-border-b tw-border-gray-200" *ngFor="let item of currentCart; let i = index">
                <div class="tw-col-span-3 tw-flex tw-items-center tw-justify-between">
                    <img *ngIf="item.urlImagen" class="tw-w-36" [src]="item.urlImagen[0]" onerror="this.src='assets/notfoundV.png'" alt="">
                    <div>
                        <p class="tw-text-sm">{{item.name}}</p>
                        <p class="tw-text-sm"><strong>SKU:</strong> {{item.sku}}</p>
                    </div>
                </div>
                <!-- <div class="tw-col-span-1"></div> -->
                <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center tw-text-orange-500 tw-font-bold">S/.{{(item.prices?.discount||0)*item.cantidad | number:'1.2-2'}}</div>
                <div class="tw-col-span-2 tw-flex tw-justify-center tw-items-center">
                    <div class="tw-flex tw-items-center tw-justify-around tw-shadow-md tw-border-solid tw-border tw-border-gray-200 tw-w-full tw-h-10">
                        <ion-button *ngIf="item.cantidad==1" (click)="removeItem(i)" fill="clear" size="small" color="dark"><i class="far fa-trash-alt"></i></ion-button>
                        <ion-button *ngIf="item.cantidad!=1"(click)="qMinus(item)" fill="clear" size="small" color="dark"><ion-icon name='remove'></ion-icon></ion-button>
                        <p class="tw-m-0">{{item.cantidad}}</p>
                        <ion-button (click)="qPlus(item)" fill="clear" size="small" color="dark"><ion-icon name='add'></ion-icon></ion-button>
                    </div>
                </div>
            </div>
            <!-- desktop -->
            <div class="tw-hidden md:tw-grid tw-grid-cols-3 md:tw-grid-cols-3 tw-py-2 tw-border-b tw-border-gray-200" *ngFor="let item of currentCart; let i = index">
                <div class="tw-col-span-3 tw-flex tw-items-center tw-justify-between">
                    <img *ngIf="item.urlImagen" class="tw-w-36" [src]="item.urlImagen[0]" alt="">
                    <div>
                        <p class="tw-text-sm">{{item.name}}</p>
                        <p class="tw-text-sm"><strong>SKU:</strong> {{item.sku}}</p>
                    </div>
                </div>
                <!-- <div class="tw-col-span-1"></div> -->
                <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center tw-text-orange-500 tw-font-bold">S/.{{(item.prices?.discount||0)*item.cantidad | number:'1.2-2'}}</div>
                <div class="tw-col-span-2 tw-flex tw-justify-center tw-items-center">
                    <div class="tw-flex tw-items-center tw-justify-around tw-shadow-md tw-border-solid tw-border tw-border-gray-200 tw-w-full tw-h-10">
                        <ion-button *ngIf="item.cantidad==1" (click)="removeItem(i)" fill="clear" size="small" color="dark"><i class="far fa-trash-alt"></i></ion-button>
                        <ion-button *ngIf="item.cantidad!=1"(click)="qMinus(item)" fill="clear" size="small" color="dark"><ion-icon name='remove'></ion-icon></ion-button>
                        <p class="tw-m-0">{{item.cantidad}}</p>
                        <ion-button (click)="qPlus(item)" fill="clear" size="small" color="dark"><ion-icon name='add'></ion-icon></ion-button>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</ion-content>
<ion-footer class="tw-bg-white">
    <ng-container *ngIf="!currentCart || currentCart.length == 0; else cartFooterNotEmpty">
        <div class="tw-p-2">
            <button class="tw-bg-orange-600 tw-text-white tw-w-full tw-font-bold tw-rounded-full tw-h-12 tw-px-3 tw-mx-1 tw-border tw-border-orange-600 tw-flex tw-justify-center tw-items-center" routerLink="/main/home" (click)="modalController.dismiss()"><i class="fas fa-shopping-cart"></i>&nbsp;&nbsp;Empezar a comprar</button>
        </div>
    </ng-container>
    <ng-template #cartFooterNotEmpty>
        <div class="tw-flex tw-justify-between tw-mx-3"><p class="tw-text-xl tw-orange-600 tw-font-bold tw-m-0">Total:</p><p class="tw-text-xl tw-text-orange-600 tw-font-bold tw-m-0">S/.{{currentOrderAmounts.subtotal | number:'1.2-2'}}</p></div>
        <div class="tw-flex tw-justify-center">
            <ion-button fill="outline" color="orange" class="tw-w-1/2 md:tw-w-48 tw-my-1 md:tw-my-2 tw-rounded-md tw-h-10 tw-normal-case" (click)="cartService.clearCart()">Limpiar</ion-button>
            <!-- <button class="tw-w-1/2 tw-text-gray-600 tw-my-3 tw-py-2 tw-rounded-lg tw-h-10" (click)="cartService.clearCart()">Limpiar</button> -->
            <ion-button fill="clear" class="tw-w-1/2 md:tw-w-48 tw-text-white tw-bg-orange-600 tw-my-1 md:tw-my-2 tw-rounded-md tw-h-10 tw-normal-case" (click)="keyboardService.escPress()" routerLink="/main/checkout"> Ir a pagar</ion-button>
        </div>
    </ng-template>
</ion-footer>
