import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-search-filter-v2',
  templateUrl: './search-filter-v2.component.html',
  styleUrls: ['./search-filter-v2.component.scss']
})
export class SearchFilterV2Component {
  @Output() sliderChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() brandsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() tagChange: EventEmitter<any[]> = new EventEmitter<any[]>();
  valuemin=1;
  valuemax=20000;
  marcas;
  filteredMarcas;
  currentFilters=[];
  currentTags=[];
  searchTerm;
  category;
  _category;
  family;
  _family;
  subfamily;
  _subfamily;
  constructor(
    public apiService: ApiService,
    private route: ActivatedRoute
  ){

  }
  ngOnInit(){
    // this.listMarcas();
  }
  // listMarcas(){
  //   this.apiService.listMarcas().subscribe((resp)=>{
  //     this.marcas=resp;
  //   })
  // }
  checkMarcas(params) {
    // const params = this.route.snapshot.queryParams;
    console.error("params['marcaID']: ",params);
    if (params) {
      const marcaIDs = new Set(params.split(','));
      this.marcas.forEach(marca => {
        const marcaIDString = String(marca.id);
        marca.selected = marcaIDs.has(marcaIDString);
        if (marca.selected && !this.currentFilters.some(item => item.id === marca.id)) {
          this.currentFilters.push(marca);
        }
      });
    }
  }
  public compareIdsMarcas(ids) {
    if (!this.marcas || this.marcas.length === 0 || !ids || ids.length === 0) {
      this.filteredMarcas = this.marcas;
      // return [];
    }
    this.filteredMarcas = this.marcas.filter(marca => ids.includes(marca.id));
    // return this.marcas.filter(marca => ids.includes(marca.id));
  }
  clearFilters(){
    this.marcas.forEach((it)=>{
      it.selected=false;
    })
    this.currentFilters=[];
    this.valuemin=1;
    this.valuemax=20000;
    this.emitBrandChange();
  }
  removeItemFilter(objetoAEliminar){
    // const objetoAEliminar = { id: 2, name: 'Objeto 2' };
    objetoAEliminar.selected=false;
    const index = this.currentFilters.findIndex(item => item === objetoAEliminar);

    if (index !== -1) {
      this.currentFilters.splice(index, 1);
    }
    this.emitBrandChange();
  }
  removeTagFilter(objetoAEliminar){
    // const objetoAEliminar = { id: 2, name: 'Objeto 2' };
    objetoAEliminar.selected=false;
    const index = this.currentTags.findIndex(item => item === objetoAEliminar);

    if (index !== -1) {
      this.currentTags.splice(index, 1);
    }
    this.emitTagChange(); 
  }
  clearTags(){
    this.currentTags=[];
  }
  emitTagChange() {
    this.tagChange.emit(this.currentTags);
  }
  emitBrandChange() {
    this.brandsChange.emit(this.currentFilters);
  }
  emitSliderChange(min,max) {
    this.sliderChange.emit({min:min,max:max});
  }
}
