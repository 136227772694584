<!-- isBom!=Y -->
<div *ngIf="item.isBom!='Y'" class="tw-flex tw-justify-center tw-items-center tw-col-span-1 tw-rounded-xl tw-shadow-lg tw-bg-white tw-min-h-[calc(550px)]">
    <div>
        <div class="tw-p-3">
            <div class="tw-w-full">
                <a (click)="openModalAddProduct(item)" class="tw-relative inline-block">
                    <img class="tw-m-auto tw-h-52 tw-object-cover" (mouseenter)="item.showButtons=true" (mouseleave)="item.showButtons=false" [src]="item.urlImagen" onerror="this.src='assets/notfoundV.png'" alt="">
                    <div *ngIf="item.showButtons" class="tw-absolute tw-left-0 tw-right-0 tw-bottom-5 tw-flex tw-justify-center tw-space-x-4">
                      <button class="tw-rounded-full tw-h-8 tw-w-8 tw-bg-orange-600 hover:tw-bg-orange-500 tw-flex tw-justify-center tw-items-center tw-transition-width tw-duration-300"><i class="fas fas far fa-heart tw-text-lg tw-text-white tw-font-bold"></i></button>
                      <button class="tw-rounded-full tw-h-8 tw-w-8 tw-bg-orange-600 hover:tw-bg-orange-500 tw-flex tw-justify-center tw-items-center tw-transition-width tw-duration-300"><i class="fas fas far fa-eye tw-text-lg tw-text-white tw-font-bold"></i></button>
                    </div>
                </a>
                <!-- <div class="md:tw-flex tw-items-center tw-text-center"><button class="tw-text-white tw-bg-blue-950 tw-my-6 tw-py-2 tw-w-full tw-rounded-lg" (click)="openModalVerFicha(item)"> Ver Ficha</button></div> -->
            </div>
            <div class="tw-w-full tw-p-1">
                <p class="tw-text-sm md:tw-text-sm tw-my-1"><strong>SKU:</strong>&nbsp;{{item.sku}}</p>
                <!-- <p class="tw-text-sm md:tw-text-sm tw-my-1"><strong>Marca</strong>&nbsp;{{item.marca?.name}}</p> -->
                <!-- <p class="tw-text-sm md:tw-text-sm"><strong>Uni. Med: </strong>UNIDAD</p> -->
                <ng-container *ngIf="item.stock && item.stock?.qty && item.stock?.qty > 0; else whatsappLink">
                    <p class=" tw-text-sm md:tw-text-sm tw-my-1 tw-font-bold">Stock: <strong class="tw-text-green-600">
                        {{ item.stock?.qty <= 10 ? ' Solo ' + item.stock?.qty : '+' + 10 }} en Stock
                    </strong></p>
                </ng-container>
                <ng-template #whatsappLink>
                    <p class="tw-text-green-600 tw-text-sm md:tw-text-sm tw-my-1"><strong>Contactar&nbsp;</strong>
                    <a [href]="'https://api.whatsapp.com/send?text='+firestoreService.empresaData?.messageStock+' '+item.name+' '+'&phone=' + firestoreService.empresaData?.telefono" target="_blank">
                        <i class="fab fa-whatsapp tw-text-green-600 tw-text-xl"></i>
                    </a>
                    </p>
                </ng-template>
                <a (click)="openModalAddProduct(item)"><p class="tw-h-16 tw-text-sm md:tw-text-sm tw-font-bold tw-line-clamp-3 tw-mb-1 tw-text-black hover:tw-text-blue-950">{{item.name}}</p></a>
            </div>
        </div>
        <div class="tw-grid tw-grid-cols-12 tw-px-4 tw-items-center tw-h-8">
            <!-- <div class="tw-col-span-6">
                <div *ngIf="item.stock" class="tw-flex tw-items-center tw-border-gray-200 tw-w-full tw-h-10">
                    <div class="tw-border tw-border-gray-200 tw-rounded-sm tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center" *ngIf="item.cantidad==1" (click)="modalController.dismiss()" fill="clear" size="small" color="dark"><a><i class="far fa-minus tw-font-bold tw-text-blue-950"></i></a></div>
                    <div class="tw-font-bold tw-text-blue-950 tw-border tw-border-gray-200 tw-rounded-sm tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center" *ngIf="item.cantidad!=1"(click)="qMinus(item)" fill="clear" size="small" color="dark"><a><i class="far fa-trash-alt tw-font-bold tw-text-blue-950"></i></a></div>
                    <p class="tw-m-0 tw-border tw-border-gray-200 tw-rounded-md tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center tw-text-gray-800">{{item.cantidad}}</p>
                    <div class="tw-font-bold tw-text-blue-950 tw-border tw-border-gray-200 tw-rounded-sm tw-w-8 tw-h-8 tw-flex tw-justify-center tw-items-center" (click)="qPlus(item)" fill="clear" size="small" color="dark"><a><i class="far fa-plus tw-font-bold tw-text-blue-950"></i></a></div>
                </div>
            </div> -->
            <div class="tw-col-span-12 tw-flex tw-justify-center tw-items-center">
                <p class="tw-text-gray-400 tw-text-xl md:tw-text-xl tw-text-center tw-mb-0 tw-line-through"><strong>S/{{item.prices?.discount||''| number:'1.2-2'}}</strong></p>&nbsp;
                <p class="tw-text-orange-600 tw-text-xl md:tw-text-2xl tw-text-center tw-mb-0"><strong>S/{{item.prices?.list||''| number:'1.2-2'}}</strong></p>
            </div>
        </div>
        <div class="tw-flex tw-items-center tw-justify-center">
            <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-lg"></i></div>
            <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-lg"></i></div>  
            <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-lg"></i></div>
            <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-lg"></i></div>
            <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-lg"></i></div>
            <span>(55)</span>
        </div>
        <div class="tw-flex tw-items-center tw-justify-center tw-py-6">
            <button (click)="addProductToCart()" class="tw-rounded-full tw-h-12 tw-w-12 hover:tw-w-44 tw-bg-orange-600 hover:tw-bg-orange-500 tw-flex tw-justify-center tw-items-center tw-transition-width tw-duration-300 addCart"><i class="fas fas fa-shopping-cart tw-text-xl tw-text-white tw-font-bold"></i></button>
        </div>
        <!-- <div class="tw-grid tw-grid-cols-2 tw-items-center">
            <div class="tw-col-span-1 tw-pr-2">
            <a [href]="'https://api.whatsapp.com/send?text='+firestoreService.empresaData?.messageStock+' '+item.name+' '+'&phone=' + firestoreService.empresaData?.telefono" target="_blank"><button class="tw-text-white tw-bg-green-600 tw-my-3 tw-py-2 tw-rounded-lg tw-m-1 tw-w-full tw-border-green-600"><i class="fab fa-whatsapp"></i>&nbsp;&nbsp;Asesor online</button></a>
            </div>
            <div class="tw-col-span-1 tw-pr-3">
            <button class="tw-text-blue-950 tw-bg-white tw-my-3 tw-py-2 tw-rounded-lg tw-m-1 tw-w-full tw-border tw-border-blue-950" [routerLink]="['/main', item.id, item.name]"><i class="fas fa-eye"></i>&nbsp;&nbsp;Ver detalle</button>
            </div>
        </div> -->
        <!-- <div class="tw-flex tw-border-t tw-border-gray-400">
            <button class="tw-text-white tw-bg-blue-950 tw-my-3 tw-py-2 tw-w-full tw-rounded-lg tw-m-1" (click)="cartService.addProductToCart(item,item.cantidad)"><i class="fas fa-shopping-cart"></i>&nbsp;&nbsp;Añadir al carrito</button>
        </div> -->
    </div>
</div>


<!-- isBom==Y -->
<div *ngIf="item.isBom=='Y'" class="tw-col-span-1 tw-rounded-xl tw-shadow-lg tw-bg-white">
    <div class="tw-p-4 tw-pb-0 tw-min-h-[calc(210px)]">
        <div class="tw-w-full tw-m-auto">
            <a (click)="openModalAddProduct(item)"><img class="tw-h-44 tw-object-cover tw-m-auto" [src]="item.urlImagen" onerror="this.src='assets/notfoundV.png'" alt=""></a>
        </div>
        <div class="tw-w-full tw-p-1">
            <div class="tw-flex tw-justify-between tw-items-center">
                <div class="">
                <a (click)="openModalAddProduct(item)"><p class="tw-text-xs md:tw-text-xs tw-font-bold tw-line-clamp-3 tw-mb-1 tw-text-black hover:tw-text-blue-950">{{item.name}}</p></a>
                <p class="tw-text-xs md:tw-text-xs tw-my-1"><strong>Código</strong>&nbsp;{{item.sku}}</p>
                </div>
                <div class="">
                <div class="tw-flex tw-items-center">
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>  
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>
                    <div class="tw-px-0.5"><i class="fas fa-star tw-text-amber-500 tw-text-base"></i></div>
                    <!-- <span>({{item.ranking}})</span> -->
                </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tw-grid tw-grid-cols-2 tw-items-center">
        <div class="tw-col-span-1 tw-pr-2">
        <a [href]="'https://api.whatsapp.com/send?text='+firestoreService.empresaData?.messageStock+' '+item.name+' '+'&phone=' + firestoreService.empresaData?.telefono" target="_blank"><button class="tw-text-white tw-bg-green-600 tw-my-3 tw-py-2 tw-rounded-lg tw-m-1 tw-w-full tw-border-green-600"><i class="fab fa-whatsapp"></i>&nbsp;&nbsp;Asesor online</button></a>
        </div>
        <div class="tw-col-span-1 tw-pr-3">
        <button class="tw-text-blue-950 tw-bg-white tw-my-3 tw-py-2 tw-rounded-lg tw-m-1 tw-w-full tw-border tw-border-blue-950" [routerLink]="['/main', item.id, item.name]"><i class="fas fa-eye"></i>&nbsp;&nbsp;Ver detalle</button>
        </div>
    </div>
    <div class="tw-flex tw-border-t tw-border-gray-400">
        <button class="tw-text-white tw-bg-blue-950 tw-my-3 tw-py-2 tw-w-full tw-rounded-lg tw-m-1" (click)="openModalDetalleProduct(item)"><i class="fas fa-info-circle"></i>&nbsp;&nbsp;Componentes</button>
    </div>
</div>