<div class="tw-px-6">
    <div>
        <ion-button fill="outline" [color]="currentFilters.length==0 ? 'gray' : 'orange' " class="tw-my-2 tw-w-full tw-rounded-md tw-h-10 tw-normal-case" [disabled]="currentFilters.length==0" (click)="clearFilters()"> Borrar Filtros</ion-button>
    </div>
    <div>
        <!-- <div class="tw-flex tw-justify-between tw-items-center tw-py-2">
            <span class="tw-fotn-bold tw-text-base">Filtros seleccionados</span>
        </div> -->
        <div class="tw-flex tw-flex-wrap">
            <div class="tw-p-0.5 tw-border tw-border-orange-600 tw-flex tw-justify-between tw-items-center tw-mx-2 tw-my-1 tw-px-1 tw-rounded-md" *ngFor="let item of currentFilters">
                <span class="tw-text-xs">{{item.name}}</span><div class="tw-ml-2" (click)="removeItemFilter(item)"><i class="fas fa-times tw-font-bold tw-text-sm"></i></div>
            </div>
            <div class="tw-p-0.5 tw-border tw-border-orange-600 tw-flex tw-justify-between tw-items-center tw-mx-2 tw-my-1 tw-px-1 tw-rounded-md" *ngFor="let item of currentTags">
                <span class="tw-text-xs">{{item.name}}</span><div class="tw-ml-2" (click)="removeTagFilter(item)"><i class="fas fa-times tw-font-bold tw-text-sm"></i></div>
            </div>
        </div>
    </div>
    <ul uk-accordion="multiple: true">
        <li class="uk-open">
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-400 tw-py-2 tw-font-bold" href>Precio</a>
            <div class="uk-accordion-content">
                <div class="tw-px-3">
                    <div class="tw-flex tw-justify-between">
                        <span>{{slidermin.value}}</span>
                        <span>{{slidermax.value}}</span>
                    </div>
                    <mat-slider class="tw-w-full custom-slider" min="1" max="20000" step="1" value="50"><!--(input)="emitSliderChange(slidermin.value,slidermax.value)"-->
                        <!-- <input matSliderThumb [(ngModel)]="value" #slider> -->
                        <input value="300" [(ngModel)]="valuemin" matSliderStartThumb #slidermin>
                        <input value="400" [(ngModel)]="valuemax" matSliderEndThumb #slidermax>
                    </mat-slider>
                    <ion-button fill="clear" class="tw-bg-amber-600 tw-text-white tw-w-full tw-font-bold tw-rounded-md tw-h-8 tw-px-3 tw-mx-1 tw-border tw-border-amber-600" (click)="emitSliderChange(slidermin.value,slidermax.value)">Buscar</ion-button>
                </div>
            </div>
        </li>
        <li *ngIf="family && !subfamily" class="uk-open">
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2 tw-font-bold" href>Filtros</a>
            <div class="uk-accordion-content">
                <div *ngFor="let item of family"><a [routerLink]="['/main/search']" [queryParams]="{ categoryID: category.id , category: category.name , familyID: item.id , family: item.name }" class="tw-no-underline tw-text-xs tw-text-gray-600 hover:tw-text-orange-600">{{item.name}}</a></div>
            </div>
        </li>
        <li *ngIf="subfamily" class="uk-open">
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2 tw-font-bold" href>Filtros</a>
            <div class="uk-accordion-content">
                <div *ngFor="let item of subfamily"><a [routerLink]="['/main/search']" [queryParams]="{ categoryID: category.id , category: category.name , familyID: family.id , family: family.name , subfamilyID:item.id , subfamily:item.name}" class="tw-no-underline tw-text-xs tw-text-gray-600 hover:tw-text-orange-600">{{item.name}}</a></div>
            </div>
        </li>
        <!-- <li>
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2" href>Componentes </a>
            <div class="uk-accordion-content">
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat proident.</p>
            </div>
        </li> -->
        <!-- <li>
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2" href>Perifericos </a>
            <div class="uk-accordion-content">
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat proident.</p>
            </div>
        </li> -->
        <!-- <li>
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2" href>Impresora </a>
            <div class="uk-accordion-content">
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat proident.</p>
            </div>
        </li> -->
        <!-- <li>
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2" href>Redes y cables </a>
            <div class="uk-accordion-content">
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat proident.</p>
            </div>
        </li> -->
        <!-- <li>
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2" href>Gaming </a>
            <div class="uk-accordion-content">
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat proident.</p>
            </div>
        </li> -->
        <li class="uk-open">
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-400 tw-py-2 tw-font-bold" href>Marca </a>
            <div class="uk-accordion-content">
                <div>
                    <div class="tw-pl-1 tw-pr-6">
                        <div class="tw-flex tw-items-center tw-border tw-border-gray-400 tw-rounded-md tw-px-2">
                            <input [(ngModel)]="searchTerm" class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 tw-mr-1 focus:tw-outline-none tw-border-none" type="text" placeholder="Buscar">
                            <div><i class="fas fas fa-search tw-font-bold tw-text-base"></i></div>
                        </div>
                        <div class="tw-h-72 tw-overflow-auto tw-mt-2">
                            <!-- <div *ngFor="let item of filteredMarcas | filterBy: ['name']: searchTerm" class="tw-flex tw-items-center tw-px-2 tw-my-1.5"> -->
                            <div *ngFor="let item of marcas | filterBy: ['name']: searchTerm" class="tw-flex tw-items-center tw-px-2 tw-my-1.5">
                                <div *ngIf="!item.selected" (click)="item.selected=true;currentFilters.push(item);emitBrandChange()"><i class="far fa-square tw-text-2xl tw-text-orange-600"></i></div>
                                <div *ngIf="item.selected" (click)="removeItemFilter(item);emitBrandChange()"><i class="fas fa-check-square tw-text-2xl tw-text-orange-600"></i></div>
                                <span class="tw-ml-2">{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <!-- <li>
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2" href>Estado del artículo </a>
            <div class="uk-accordion-content">
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat proident.</p>
            </div>
        </li> -->
        <!-- <li>
            <a class="uk-accordion-title tw-text-base tw-border-b tw-border-gray-200 tw-py-2" href>Disponibilidad </a>
            <div class="uk-accordion-content">
                <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat proident.</p>
            </div>
        </li> -->
    </ul>
</div>