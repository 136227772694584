export const environment = {
  production: true,
  api_test: "https://apis.simplex-erp.com/ecommerce/",
  version_test: 'api-ecommerce/v1/',
  api_prod: "https://apis.simplex-erp.com/ecommerce/",
  version_prod: 'subacom/v1/',
  maquinarias_prod: 'maquinarias/v1/',
  token : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDI0NTU3Iiwicm9sZSI6IkFkbWluIiwiY29tcGFueUlEIjoiMTAwMDAwOSIsImNvbXBhbnlDb2RlIjoiR01JUkFZQSIsImlhdCI6MTY2MDEzNTEzMX0.gzIMhQVNcpAM2YPEwk96dikV7fcDcdj8iUKIgGfiQtY',
  firebase:{
    apiKey: "AIzaSyDc2T004pMrO60OC2fYWz5-ol3satLrO9w",
    authDomain: "simplex-ecommerce.firebaseapp.com",
    projectId: "simplex-ecommerce",
    storageBucket: "simplex-ecommerce.appspot.com",
    messagingSenderId: "412060463829",
    appId: "1:412060463829:web:f5bfe78dd08385d8b8285c",
    measurementId: "G-6R2G99H9CB"
  },
  iziPayPK:"82938120:testpublickey_d2tccwSRH2FK1HvERcDxmuQEutY6kTaBMFQ668aEUwQoM",
  access_token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDI0NTU3Iiwicm9sZSI6IkFkbWluIiwiY29tcGFueUlEIjoiMTAwMDAwOSIsImNvbXBhbnlDb2RlIjoiR01JUkFZQSIsImlhdCI6MTY2MDEzNTEzMX0.gzIMhQVNcpAM2YPEwk96dikV7fcDcdj8iUKIgGfiQtY'
};