import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetalertsService {
  ///////OBSERVABLE ORIGIN SUBSCRIBER FOR EVENTS/////////
  //public subjectCartHaveProducts$ = new Subject();

  constructor() { }
  loginSUCCESS(){
    Swal.fire({
      title: 'Bienvenido!',
      //position: 'top-end',
      text: 'Elige Ordena Disfruta.',
      showCancelButton: false,
      showConfirmButton: false,
      icon: 'success',
      timer: 1500
    })
  }
  logoutSUCCESS(){
    Swal.fire({
      title: 'Hasta Luego!',
      //position: 'top-end',
      text: 'Regresa pronto y sigue comprando.',
      showCancelButton: false,
      showConfirmButton: false,
      icon: 'success',
      timer: 2000
    })
  }
  loading(message?){
    if(message){
      let timerInterval;
        Swal.fire({
          title: 'Espere por favor !',
          text:message,
          imageUrl: "assets/loader.gif",
          showCancelButton: false,
          showConfirmButton: false,
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
        })
    }else{
      let timerInterval;
        Swal.fire({
          title: 'Espere por favor !',
          imageUrl: "assets/loader.gif",
          showCancelButton: false,
          showConfirmButton: false,
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
        })
    }

  }
  dissmissSwal(){
    Swal.close();
  }
  loginERROR(){
    Swal.fire({
      title: 'Ooops!',
      text: 'Datos de usuario incorrectos.',
      icon: 'error',
      confirmButtonText: 'Continuar'
    })
  }

  public alertaErronea(mensaje){
    Swal.fire({
      title: 'Ooops!',
      text: mensaje,
      icon: 'error',
      confirmButtonText: 'Continuar'
    })
  }

  public algoHaSalidoMal(){
    Swal.fire({
      title: 'Ooops!',
      text: "Algo ha salido mal intentelo más tarde",
      icon: 'error',
      confirmButtonText: 'Continuar'
    })
  }

  signupSUCCESS(){
    Swal.fire({
      title: 'Usuario Registrado!',
      //position: 'top-end',
      text: 'Elige Ordena Disfruta.',
      showCancelButton: false,
      showConfirmButton: false,
      icon: 'success',
      timer: 1500
    })
  }
  signupERROR(message){
    Swal.fire({
      title: 'Ooops!',
      text: message,
      icon: 'error',
      confirmButtonText: 'Continuar'
    })
  }
  generalSuccess(message, timer?){
    if(timer){
      Swal.fire({
        title: message,
        icon: 'success',
        timer: timer,
        showConfirmButton: false,
        confirmButtonColor: '#ea580c',
      })
    }else{
      Swal.fire({
        title: message,
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#ea580c',
      })
    }
  }
  generalError(message){
    Swal.fire({
      title: message,
      //text: message,
      icon: 'error',
      confirmButtonText: 'OK',
      confirmButtonColor: '#ea580c',
    })
  }
  generalWarning(message, timer?){
    if(timer){
      Swal.fire({
        title: message,
        icon: 'warning',
        timer: timer,
        showConfirmButton: false,
        confirmButtonColor: '#ea580c',
      })
    }else{
      Swal.fire({
        title: message,
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: '#ea580c',
      })
    }
  }
  ///////////////////////////////CUSTOM SWEET ALETS///////////////
  ////////(///CART HAVE PRODUCTS///////////////////
  // async showAlertCartHaveProducts(data, source){
  //   return Swal.fire({
  //     title: 'Ya tiene productos de otro restaurante',
  //     text: "Que desea hacer?",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Ver Carrito',
  //     cancelButtonText: 'Vaciar Carrito y Agregar',
  //     reverseButtons: true
  //   }).then((result) => {
  //     if (result.value) {
  //       //console.log('Ver Carrito');
  //       //this.subjectCartHaveProducts$.next({operation:1,data:data})
  //       return {operation:1}
  //       //this.openCart();
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       //console.log('Vaciar Carrito y Agregar');
  //       //this.subjectCartHaveProducts$.next({operation:2,data:data})
  //       return {operation:2,data:data}
  //     }
  //   })
  // }
  /////////////////////////////FIRST ADD ADDRESS///////////////////////
  async firstAddOneAddress(){
    return Swal.fire({
      title: 'Primero cree una nueva dirección.',
      // text: "Que desea hacer?",
      icon: 'warning',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Agregar',
      reverseButtons: true
    }).then((result) => {
      result.value;
    })
  }
  //////////////////////DESEA ELIMINAR EL PRODUCTO////////////////////////

  async areYouSureRemoveProduct(){
    return Swal.fire({
      title: '¿Quitar del carrito?',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.generalSuccess("Se quito el producto", 1500);
        return true;
      }else{
        return false;
      }
    })
  }
  async areYouSureRemoveAddress(name){
    return Swal.fire({
      title: '¿Eliminar '+name+'?',
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.generalSuccess("Se elimino la dirección", 1500);
        return true;
      }else{
        return false;
      }
    })
  }
  async questionAlert(title,confirmButtonText?,cancelButtonText?,success?,error?){
    return new Promise((resolve)=>{
      return Swal.fire({
        title: title,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: '#ea580c',
        cancelButtonColor: '#6b7280',
        confirmButtonText: confirmButtonText?confirmButtonText:"Si",
        cancelButtonText: cancelButtonText?cancelButtonText:"No",
      }).then((result) => {
        if (result.value) {
          success?this.generalSuccess(success, 1500):{};
          result.value?resolve(true):resolve(false);
        }else{
          error?this.generalError(success):{};
          resolve(false);
        }
      })
    })
  }
}
