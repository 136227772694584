 <ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-title class="tw-text-lg tw-text-center">{{ item.name }}</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="modalController.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
      <div class="tw-block tw-p-6">
          <div class="tw-grid tw-grid-cols-6 md:tw-grid-cols-6 tw-border-b tw-border-gray-200 tw-font-bold tw-py-2 tw-text-center">
              <div class="tw-col-span-2">Producto</div>
              <div class="tw-col-span-1">Marca</div>
              <div class="tw-col-span-1">Código</div>
              <div class="tw-col-span-1">Cantidad</div>
              <div class="tw-col-span-1"></div>
          </div>
          <div class="tw-grid tw-grid-cols-6 md:tw-grid-cols-6 tw-py-2 tw-border-b tw-border-gray-200" *ngFor="let item of tablero; let i = index">
              <div class="tw-col-span-2 tw-flex tw-items-center tw-justify-between">
                  <img class="tw-w-36" [src]="item['product'].urlImagen" alt="">
                  <div>
                      <p class="tw-line-clamp-3">{{item['product'].name}}</p>
                  </div>
              </div>
              <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center">{{item['product'].marca?.name}}</div>
              <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center">{{item['product'].sku}}</div>
              <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center">{{item.qty}}</div>
              <div class="tw-col-span-1 tw-flex tw-justify-center tw-items-center">
                <button class="tw-text-white tw-bg-blue-950 tw-my-3 tw-py-2 tw-w-1/2 tw-rounded-lg tw-m-1 tw-hidden md:tw-block" [routerLink]="['/main', item.product.id, item.name]" (click)="modalController.dismiss()">Detalle</button>
                <button class="tw-text-white tw-bg-blue-950 tw-my-3 tw-py-2 tw-w-1/2 tw-rounded-lg tw-m-1 tw-block md:tw-hidden" [routerLink]="['/main', item.product.id, item.name]" (click)="modalController.dismiss()"><i class="far fa-plus tw-font-bold tw-text-white"></i></button>
              </div>
          </div>
      </div>
  </ion-content>