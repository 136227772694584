<div class="tw-hidden md:tw-block md:tw-absolute tw-bg-white tw-px-20 tw-z-30 tw-top-24 tw-w-full">
    <div class="tw-block md:tw-flex tw-justify-around tw-items-center tw-h-full md:tw-h-20">
        <div class="tw-flex tw tw-items-center tw-justify-center">
            <div class="tw-flex tw-justify-center tw-items-center tw-mx-2">
                <button class="tw-w-52 tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-px-3 tw-py-2 tw-bg-gray-200 tw-rounded-md">
                    <i class="fas fa-map-marker-alt tw-text-xl tw-text-gray-400"></i>
                    <span class="tw-text-stone-900 tw-font-bold tw-ml-3 tw-line-clamp-2">Calle Octavio Muñoz Najar N°223, Centro Comercial CompuArequipa</span>
                </button>
            </div>
            <div class="tw-flex tw-justify-center tw-items-center tw-mx-2">
                <button routerLink="/main/locales" class="tw-w-52 tw-flex tw-justify-start md:tw-justify-between tw-items-center tw-bg-orange-600 tw-px-3 tw-py-1 tw-rounded-md">
                    <i class="fas fa-map-marker-alt tw-text-xl tw-text-white"></i>
                    <div>
                        <p class="tw-text-white tw-font-bold tw-ml-3 tw-mb-0 tw-text-xs">Sede Arequipa</p>
                        <p class="tw-text-white tw-font-bold tw-ml-3 tw-mb-0 tw-text-sm">CompuArequipa</p>
                    </div>
                    <i class="fas fas fa-chevron-circle-right tw-text-xl tw-text-white"></i>
                </button>
            </div>
        </div>
        <!-- <div class="tw-flex tw tw-items-center tw-justify-around">
            <div class="tw-flex tw-justify-center tw-items-center tw-mx-2">
                <button routerLink="/main/profile/misfavoritos" class="tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-bg-orange-600 tw-px-3 tw-py-2 tw-rounded-md">
                    <i class="fas fa-heart tw-text-xl tw-text-white"></i>
                    <span class="tw-text-white tw-font-bold tw-ml-3">Mis favoritos</span>
                </button>
            </div>
            <div class="tw-flex tw-justify-center tw-items-center tw-mx-2">
                <button routerLink="/main/profile/historial" class="tw-flex tw-justify-start md:tw-justify-center tw-items-center tw-bg-orange-600 tw-px-3 tw-py-2 tw-rounded-md">
                    <i class="fas fa-calendar tw-text-xl tw-text-white"></i>
                    <span class="tw-text-white tw-font-bold tw-ml-3">Historial de ventas</span>
                </button>
            </div>
        </div> -->
    </div>
</div>