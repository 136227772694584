<ion-header class="ion-no-border">
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="modalController.dismiss()" size="small">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
<ion-content>
  <div class="tw-p-3">
    <div><p class="tw-text-xl tw-font-bold tw-text-slate-900 tw-ml-4">DATOS DE CLIENTE</p></div>
    <div class="tw-px-3">
        <!-- <ul uk-tab>
            <li (click)="activeTab=1;"><a class="tw-normal-case">Persona<div *ngIf="activeTab==1" class="tw-ml-2"><i class="far fa-dot-circle tw-text-green-600"></i></div><div *ngIf="activeTab==2" class="tw-ml-2"><i class="far fa-circle"></i></div></a></li>
            <li (click)="activeTab=2;"><a class="tw-normal-case">Empresa<div *ngIf="activeTab==2" class="tw-ml-2"><i class="far fa-dot-circle tw-text-green-600"></i></div><div *ngIf="activeTab==1" class="tw-ml-2"><i class="far fa-circle"></i></div></a></li>
        </ul> -->
        <ul uk-tab>
          <li (click)="activeTab=1;"><a class="tw-normal-case">Persona<i [ngClass]="activeTab==1?'far fa-dot-circle tw-text-green-600':'far fa-circle'" class="tw-ml-2"></i></a></li>
          <li (click)="activeTab=2;"><a class="tw-normal-case">Empresa<i [ngClass]="activeTab==2?'far fa-dot-circle tw-text-green-600':'far fa-circle'" class="tw-ml-2"></i></a></li>
        </ul>
        <ul class="uk-switcher uk-margin">
            <li>
              <form [formGroup]="formCliente">
                <p class="tw-text-gray-600 tw-text-center tw-text-xs md:tw-text-base">Obtén tu proforma ingresando tus datos</p>
                <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-1">
                  <div class="tw-col-span-1 md:tw-col-span-2 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-1">
                    <!-- <div>
                        <p class="tw-text-gray-600 tw-my-2 md:tw-my-1.5 tw-font-bold">Tipo de documento</p>
                        <select class="tw-rounded-md tw-w-full tw-text-lg tw-p-1.5 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none"  formControlName="type_doc">
                            <option value="DNI">DNI</option>
                            <option value="Pasaporte">Pasaporte</option>
                            <option value="Carnet Extranjeria">Carnet Extranjeria</option>
                        </select>
                    </div> -->
                    <div>
                        <p class="tw-text-gray-600 tw-my-2 md:tw-my-1.5 tw-font-bold"># Documento</p>
                        <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="number" formControlName="num_doc">
                    </div>
                    <div>
                      <p class="tw-text-gray-600 tw-my-2 md:tw-my-1.5 tw-font-bold">Celular</p>
                      <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="number" formControlName="phone" placeholder="51900000000">
                    </div>
                  </div>
                  <div>
                      <p class="tw-text-gray-600 tw-my-2 md:tw-my-1.5 tw-font-bold">Nombre</p>
                      <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="text" formControlName="name">
                  </div>
                  <div>
                      <p class="tw-text-gray-600 tw-my-2 md:tw-my-1.5 tw-font-bold">Apellidos</p>
                      <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="text" formControlName="lastname">
                  </div>
                  <div>
                      <p class="tw-text-gray-600 tw-my-2 md:tw-my-1.5 tw-font-bold">Correo</p>
                      <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="text" formControlName="email" placeholder="ejemplo@gmail.com">
                  </div>
                  <!-- <div>
                      <p class="tw-text-gray-600 tw-my-2 md:tw-my-1.5 tw-font-bold">Celular</p>
                      <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="number" formControlName="phone" placeholder="51900000000">
                  </div> -->
                </div>
                <!-- <div class="tw-flex tw-justify-end tw-py-4">
                  <ion-button fill="clear" class="tw-text-white tw-bg-orange-600 tw-my-0.5 md:tw-my-2 tw-rounded-md tw-h-10 tw-normal-case" [disabled]="!formCliente.valid" (click)="download()"> Descargar proforma</ion-button>
                </div> -->
              </form>
            </li>
            <li>
              <form [formGroup]="formClienteRuc">
                <p class="tw-text-gray-600 tw-text-center tw-mt-2 tw-mb-6 tw-text-xs md:tw-text-base">Obtén tu proforma ingresando tus datos</p>
                <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-1">
                  <div>
                      <p class="tw-text-gray-600 tw-my-2 md:tw-my-3 tw-font-bold">RUC</p>
                      <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="text" formControlName="ruc">
                  </div>
                  <div>
                      <p class="tw-text-gray-600 tw-my-2 md:tw-my-3 tw-font-bold">Razon Social</p>
                      <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="text" formControlName="razon">
                  </div>
                  <div>
                      <p class="tw-text-gray-600 tw-my-2 md:tw-my-3 tw-font-bold">Direccion</p>
                      <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="text" formControlName="direccion">
                  </div>
                  <div>
                    <p class="tw-text-gray-600 tw-my-2 md:tw-my-3 tw-font-bold">Celular</p>
                    <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="number" formControlName="phone" placeholder="51900000000">
                  </div>
                  <div>
                    <p class="tw-text-gray-600 tw-my-2 md:tw-my-1.5 tw-font-bold">Correo</p>
                    <input class="tw-rounded-md tw-w-full tw-text-lg tw-p-1 tw-px-3 tw-border tw-border-gray-400 focus:tw-outline-none" type="text" formControlName="email" placeholder="ejemplo@gmail.com">
                  </div>
                </div>
              </form>
            </li>
        </ul>
    </div>
  </div>
</ion-content>
<ion-footer>
  <div class="tw-px-4">
    <ion-button *ngIf="activeTab==1" fill="clear" class="tw-text-white tw-bg-orange-600 tw-my-0.5 md:tw-my-2 tw-rounded-md tw-h-10 tw-normal-case tw-w-full" [disabled]="!formCliente.valid" (click)="download(1)"> Descargar proforma</ion-button>
    <ion-button *ngIf="activeTab==2" fill="clear" class="tw-text-white tw-bg-orange-600 tw-my-0.5 md:tw-my-2 tw-rounded-md tw-h-10 tw-normal-case tw-w-full" [disabled]="!formClienteRuc.valid" (click)="download(2)"> Descargar proforma</ion-button>
  </div>
</ion-footer>