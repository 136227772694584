import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription, map, of, switchMap, take } from 'rxjs';
import {v4 as uuidv4} from 'uuid';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  private $_subscriptions: Subscription;
  private chatsCollection: AngularFirestoreCollection<any>;
  private empresasCollection: AngularFirestoreCollection<any>;
  currentEmpresaData$ = new BehaviorSubject(null);
  public empresaData;
  currentUser;
  constructor(
    private db:AngularFirestore,
    public userService: UserService
  ) { 
    this.userService.currentUserSubject$.subscribe((user)=>{
      this.currentUser=user;
    })
    this.chatsCollection = this.db.collection<any>('chats');
    this.empresasCollection = this.db.collection<any>('empresas');
    this.getWSPmessges();
  }
  get(collection, id) {
    return new Observable((observer) => {
      this.$_subscriptions = this.db.collection(collection).doc(id).valueChanges().pipe(take(1)).subscribe((data) => {
        observer.next(data);
        this.$_subscriptions.unsubscribe();
      }, (error) => {
        observer.error(error);
        this.$_subscriptions.unsubscribe();
      });
    });
  }
 getAll(collection){
  return new Observable((resp) => {
    this.$_subscriptions = this.db.collection(collection).valueChanges().subscribe((data)=>{
      resp.next(data);
      this.$_subscriptions.unsubscribe();
    },(error)=>{
      resp.error(error);
      this.$_subscriptions.unsubscribe();
    })
  });
 }
 getAllFive(collection) {
  return new Observable((observer) => {
    const subscription = this.db.collection(collection).snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data:any = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { documentId: id, ...data };
        });
      }),
      take(5)
    ).subscribe(
      (data) => {
        observer.next(data);
        subscription.unsubscribe();
      },
      (error) => {
        observer.error(error);
        subscription.unsubscribe();
      }
    );
  });
}
 update(collection,data,id){
  return new Observable((resp)=>{
    this.db.collection(collection).doc(id).update(data).then(() =>{
      resp.next("ok");
    }).catch((error)=> {
      resp.error(error);
    })
  });
 }
 set(collection,data,id?){
  console.info("collection: ",collection,"data: ",data,"id",id);
  return new Observable((resp)=>{
    this.db.collection(collection).doc(id?String(id):uuidv4()).set(data).then(() =>{
      resp.next("ok");
    }).catch((error)=> {
      resp.error(error);
    })
  });
 }
 setUnique(collection, data, id?) {
  return new Observable((observer) => {
    if (id) {
      this.db.collection(collection).doc(String(id)).get().toPromise().then((doc) => {
        if (doc.exists) {
          if (doc.data()['active']) {
            observer.next(201); // recurso activo
          } else {
            observer.next(409); // recurso inactivo
          }
        } else {
          this.db.collection(collection).doc(String(id)).set(data).then(() => {
            observer.next(200);//recurso ok
          }).catch((error) => {
            observer.error(error);
          });
        }
      }).catch((error) => {
        console.error("setUniqueERROR: ",error);
        observer.error(500);
      });
    } else {
      const generatedId = uuidv4();
      this.db.collection(collection).doc(generatedId).set(data).then(() => {
        observer.next("ok");
      }).catch((error) => {
        observer.error(error);
      });
    }
  });
}
 delete(collection,id){
  return new Observable((resp)=>{
    this.db.doc(`${collection}/${id}`).delete().then(()=>{
      resp.next("ok");
    }).catch((error)=>{
      resp.error(error);
    });
  })
 }
 deleteRecursive(collection,key): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const batch = this.db.firestore.batch();
    const docRef = this.chatsCollection.doc(String(key)).ref;
    docRef
      .collection(collection).get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
        batch.delete(docRef);
        return batch.commit();
      })
      .then(() => {
        resolve();
      }).catch((error) => {
        reject(error);
      });
  });
}
 deleteAll(collection){
  return new Observable((resp)=>{
    this.db.doc(collection).delete().then(()=>{
      resp.next("ok");
    }).catch((error)=>{
      resp.error(error);
    });
  })
 }

 /*
 *MESSAGES
 */
  getMessages(key: string): Observable<any[]> {
    return this.chatsCollection.doc(key).collection('messages', ref => ref.orderBy('timestamp', 'asc')).valueChanges({ idField: 'id' });
  }
  // deleteMessages(key: string) {//no permite borrados aotmicos
  //   this.chatsCollection.doc(key).delete().then(() => {
  //     console.log('El documento se eliminó correctamente.');
  //   })
  //   .catch((error) => {
  //     console.error('Error al eliminar el documento:', error);
  //   });
  // }
  deleteMessages(key: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const batch = this.db.firestore.batch();
      const docRef = this.chatsCollection.doc(key).ref;
      docRef
        .collection('messages').get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
          batch.delete(docRef);
          return batch.commit();
        })
        .then(() => {
          resolve();
        }).catch((error) => {
          reject(error);
        });
    });
  }
  addMessage(key: string, content: string, remitente: string) {
    const message = {
      content: content,
      origin: 'dealer',
      type:'text',
      timestamp: new Date(),
      remitenteId: remitente
    };
    this.chatsCollection.doc(key).collection('messages').add(message);
  }
  
  getWSPmessges(){
    this.empresasCollection.doc('SUBACOM').get().subscribe(doc => {
      this.empresaData=doc.data();
      this.currentEmpresaData$.next(this.empresaData);
    });
  }
  // `empresas/SUBACOM/users/${this.currentUser.email}/direcciones/`
  getEmpresa() {
    return this.empresasCollection.doc('SUBACOM').valueChanges({ idField: 'id' });
  }
  getDirecciones() {
    return this.empresasCollection.doc('SUBACOM')
      .collection(`users/${this.currentUser.email}/direcciones`, ref => ref.where('active', '==', true))
      .valueChanges({ idField: 'id' });
  }
  getFavoritos() {
    return this.empresasCollection.doc('SUBACOM')
      .collection(`users/${this.currentUser.email}/favoritos`, ref => ref.where('active', '==', true))
      .valueChanges({ idField: 'id' });
  }
  getHistorial() {
    return this.empresasCollection.doc('SUBACOM')
      .collection(`users/${this.currentUser.email}/pedidos`)
      .valueChanges({ idField: 'id' });
  }
  getCotizaciones(usuId) {
    return this.empresasCollection.doc('SUBACOM')
      .collection('cotizacion', ref => ref.where('usu_id', '==', usuId))
      .valueChanges({ idField: 'id' });
  }
  getPedidos(usuId) {
    return this.empresasCollection.doc('SUBACOM')
      .collection('pedidos', ref => ref.where('usu_id', '==', usuId))
      .valueChanges({ idField: 'id' });
  }
  getCuentas() {
    return this.empresasCollection.doc('SUBACOM')
      .collection('cuentas')
      .valueChanges({ idField: 'id' });
  }
  getSucursales() {
    return this.empresasCollection.doc('SUBACOM')
      .collection('sucursales', (ref) => ref.where('active', '==', true))
      .valueChanges({ idField: 'id' });
  }

  //////////firesotre user///////////////////
  updateUser(data) {
    return this.update(`empresas/SUBACOM/users`, data, this.currentUser.email).pipe(
      switchMap((resp) => {
        this.get(`empresas/SUBACOM/users`, this.currentUser.email).subscribe((userResp) => {
          this.userService.setCurrentUser(userResp);
        });
        return of(resp);
      })
    );
  }
}
