<ion-header>
    <ion-toolbar>
      <ion-buttons slot="end">
        <ion-button (click)="modalController.dismiss()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div>
        <div class="tw-px-6 tw-py-2">
            <ul uk-accordion="collapsible: false" id="datosCotizacion" #datosCotizacion>
                <li>
                    <a class="uk-accordion-title" href="#">
                        <div class="tw-flex tw-justify-start tw-items-center">
                            <div class="tw-bg-blue-900 tw-rounded-full tw-py-1 tw-px-3"><span class="tw-text-white">1</span></div><p class="tw-m-0 tw-ml-3 tw-font-bold">Busqueda</p>
                        </div>
                    </a>
                    <!-- 1 -->
                    <div class="uk-accordion-content">
                        <ion-searchbar #ionSearchbar id="ionSearchbar" class="tw-border tw-border-gray-600 tw-rounded-full customSearch" placeholder="Buscar"></ion-searchbar>
                    </div>
                </li>
                <li>
                    <a class="uk-accordion-title" href="#">
                        <div class="tw-flex tw-justify-start tw-items-center">
                            <div class="tw-bg-blue-900 tw-rounded-full tw-py-1 tw-px-3"><span class="tw-text-white">2</span></div><p class="tw-m-0 tw-ml-3 tw-font-bold">Marcas</p>
                        </div>
                    </a>
                    <!-- 2 -->
                    <div class="uk-accordion-content">
                        <div class="tw-col-span-1 tw-h-[calc(60vh)] tw-overflow-auto">
                            <ion-searchbar #brandIonSearchbar id="brandIonSearchbar" [value]="searchValue" class="tw-border tw-border-gray-600 tw-rounded-full customSearch" placeholder="Buscar"></ion-searchbar>
                            <div>
                                <div *ngFor="let item of _listMarcas | filterBy: ['name']: brandIonSearchbar.value">
                                    <div class="tw-flex tw-p-1"><div class="tw-rounded-md tw-border tw-border-gray-600 tw-w-7 tw-flex tw-items-center tw-justify-center" (click)="item.selected=!item.selected"><i *ngIf="item.selected" class="fas fa-check"></i></div> &nbsp;&nbsp;<span>{{item.name}}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>                
        </div>
    </div>
  </ion-content>
  <ion-footer>
    <ion-row>
        <ion-col>
            <button class="tw-bg-blue-950 tw-text-white tw-font-bold tw-w-full tw-rounded-full tw-h-12 tw-px-3 tw-mx-1 tw-border tw-border-blue-950 tw-flex tw-justify-around tw-items-center" type="button" (click)="searchTableros()">Tableros</button>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <button class="tw-bg-blue-950 tw-text-white tw-font-bold tw-w-full tw-rounded-full tw-h-12 tw-px-3 tw-mx-1 tw-border tw-border-blue-950 tw-flex tw-justify-around tw-items-center" type="button" (click)="search()">Filtrar</button>
        </ion-col>
    </ion-row>
  </ion-footer>