import { Component, EventEmitter, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.scss']
})
export class CategoryMenuComponent {
  current;
  categories;
  @Output() closeMenu = new EventEmitter<any>();
  constructor(
    public modalController: ModalController,
    public apiService: ApiService
  ){
  }
  ngOnInit(){
    this.listCategories();
  }
  onMouseEnter(event){
    // console.info("onMouseEnter: ",event);
    this.current=event;
  }
  setDefault(){
    this.current=this.categories[0];
    console.info("setDefault: ",this.current);
  }
  listCategories(){
    this.apiService.listCategories().subscribe((resp)=>{
      console.info("listCategories: ",resp);
      this.categories=resp;
      this.setDefault();
    })
  }
  closeMenuEvent() {
    console.info("*******************:");
    this.closeMenu.emit({
      close: true,
    });
  }
}
